import React from 'react'
import Footer from 'components/Footer/Footer'

import styles from './Legal.module.scss'

const TermsAndConditions = () => {
  return (
    <>
      <div className={styles.container}>
        <h2>Terms of Use</h2>
        <p className={styles.content}>
          By signing up for and using SureSure, you agree to be bound by the following terms of use:
        </p>

        <div>
          <h3>Updates</h3>
          <ol>
            <li>SureSure reserves the right to update and change the Terms of Use from time to timewithout notice. </li>
            <li>Any new features that augment or enhance the current Service, including the releaseof new tools and resources, shall be subject to the Terms of Use. </li>
            <li>Continued use of the Service after any such changes shall constitute your consent to such changes. You can review the most current version of the Terms of Use at any time at: [URL]</li>
          </ol>

          <h3>Breach</h3>
          <ol>
            <li>Violation of any of the terms below may result in the termination of your Account, at SureSure’s sole discretion. </li>
          </ol>

          <h3>Account requirements</h3>
          <ol>
            <li>You must be an incorporated company or human 16 years or older to use this Service.</li>
            <li>Accounts registered by “bots” or other automated methods are not permitted.</li>
            <li>You must provide your legal full name, a valid email address, and any other information requested in order to complete the signup process. </li>
          </ol>

          <h3>Account management</h3>
          <ol>
            <li>Your login may only be used by one person – a single login shared by multiple people is not permitted. You may create separate logins for persons using your account.</li>
            <li>You are responsible for maintaining the security of your account and password. </li>
            <li>SureSure cannot and will not be liable for any loss or damage from your failure to comply with this security obligation.</li>
            <li>You are responsible for all activity that occurs under your account (even when items are recorded by others who have accounts under your account). </li>
            <li>You acknowledge that SureSure has no control over and no duty to take any action regarding the recording of items on the Service. </li>
            <li>You release SureSure from all liability for you having acquired or not acquired Content through the Services. </li>
          </ol>

          <h3>Organization Accounts and Account Administrators</h3>
          <ol>
            <li>When you sign up for an account for your organization you may specify one or more administrators. </li>
            <li>The administrators will have read and write privileges, and can authorise other users to join the organization’s account. Users will have read and write privileges but can not authorise other users to join the organisation’s account.  </li>
            <li>You are responsible for</li>
            <ol type='a'>
              <li>ensuring confidentiality of your organization account password</li>
              <li>appointing competent individuals as administrators for managing your organization account; and</li>
              <li>ensuring that all activities that occur in connection with your organization account comply with this Agreement. </li>
            </ol>
            <li>You understand that SureSure is not responsible for account administration and internal management of the Services for you.</li>
          </ol>

          <h3>Account Data</h3>
          <ol>
            <li>You and your authorized users may choose to provide, post, input, submit, or otherwise make accessible to SureSure, data or information about you, your assets, your employees, customers, or any third party (“Data“), and SureSure may store suchData on your behalf, all in connection with your use of the Service. </li>
            <li>You hereby consent for SureSure to use, upload, and store Data solely in connection with provision of the Service. </li>
            <li>SureSure will endeavor to at all times comply with the Protection of Personal Information Act.</li>
            <li>If you provide, post, input, submit, or otherwise make accessible to SureSure, data orinformation about:</li>
            <ol type='a'>
              <li>a South African person or entity you expressly declare that you have the necessary consent from the Data Subject under POPIA to enter the information or Data into SureSure; and</li>
              <li>an EU Citizen (i.e. Data Subject under GDPR) you expressly declare that you have the necessary consent from the Data Subject under the GDPR to enter the information or Data into SureSure; and</li>
              <li>you are solely responsible for garnering such consent and keeping a record thereof. </li>
            </ol>

            <li>You represent and warrant that:</li>
            <ol type='a'>
              <li>you own all Data or have all rights that are necessary to grant SureSure the data related consents under these Terms of Use;</li>
              <li>your collection of Data has and will be in compliance with all applicable laws and regulations, including without limitation those concerning data or information privacy; and d</li>
              <li>neither the Data, nor the inclusion of Data in or use of Data in connection with the Service, will infringe, misappropriate or violate any Intellectual Property Rights, or violate the privacy rights, of any third party, or result in the violation of any applicable law or regulation, including without limitation those concerning data or information privacy.  </li>
            </ol>

            <li>As between you and SureSure, you retain exclusive ownership of the Data, and you acknowledge and agree that you remain solely responsible and liable for the Data. </li>
            <li>You may download your Data at any time during the Term, or as otherwise set forth herein, provided you comply with these Terms and SureSure security requirements.</li>
          </ol>

          <h3>Warranty Disclaimer</h3>
          <ol>
            <li>SureSure has no special relationship with or fiduciary duty to you.</li>
            <li>The Services, Content and WebApp are provided on an “as is” and “as available” basis, without warranties of any kind, either express or implied. </li>
            <li>SureSure does not warrant that:</li>
            <ol type='a'>
              <li>the service will meet your specific requirements;</li>
              <li>the service will be uninterrupted, timely, secure, or error-free;</li>
              <li>the information recorded by the user is accurate or reliable; </li>
              <li>the quality of any products, services, information, or other material purchased or obtained by you through the service will meet your expectations; </li>
              <li>any errors in the Service will be corrected. </li>
            </ol>
          </ol>

          <h3>Personal Data</h3>
          <ol>
            <li>For information regarding SureSure’s treatment of personally identifiable information, please review SureSure’s current Privacy Policy, which is hereby incorporated by reference.</li>
            <li>Your acceptance of this Agreement constitutes your acceptance and agreement to bebound by SureSure’s Privacy Policy.</li>
          </ol>

          <h3>Communications from SureSure</h3>
          <ol>
            <li>The Service may include certain communications from SureSure, such as service announcements, administrative messages and newsletters.</li>
            <li>You understand that these communications shall be considered part of using the Services.</li>
            <li>As part of our policy to provide you total privacy, we also provide you the option of opting out from receiving newsletters from us. However, you will not be able to opt-out from receiving service announcements and administrative messages.</li>
          </ol>

          <h3>Indemnity</h3>
          <ol>
            <li>You will indemnify and hold SureSure, its parents, subsidiaries, affiliates, officers, andemployees harmless (including, without limitation, from all damages, liabilities, settlements, costs and attorneys’ fees) from any claim or demand made by any third party due to or arising out of your access to the Services, use of the Services, your violation of this Agreement, or the infringement by you of any intellectual property or other right of any person or entity </li>
          </ol>

          <h3>Cancellation and Termination</h3>
          <ol>
            <li>You are solely responsible for properly canceling your account. </li>
            <li>Please note that account data is not automatically removed upon cancellation as many of our customers re-activate their accounts after cancellation. We store all datapermanently. </li>
            <li>If you want your account data to be completely removed from the system, please email support@SureSure.co.za with the request and we will have the account data removed. This information cannot be recovered once deleted. </li>
            <li>SureSure, in its sole discretion, has the right to suspend or terminate your account and refuse any and all current or future use of the Service, or any other SureSure service, for any reason at any time.</li>
            <li>Such termination of the Service will result in the deactivation or deletion of your Account or your access to your Account, and the forfeiture and relinquishment of all Content in your Account. </li>
            <li>SureSure reserves the right to refuse service to anyone for any reason at any time. </li>
          </ol>

          <h3>Modifications to the Service and Prices</h3>
          <ol>
            <li>SureSure reserves the right at any time and from time to time to modify or discontinue, temporarily or permanently, the Service (or any part thereof) with or without notice. </li>
            <li>SureSure shall not be liable to you or to any third party for any modification, suspension or discontinuance of the Service.</li>
          </ol>

          <h3>Copyright and Content Ownership</h3>
          <ol>
            <li>SureSure claims no intellectual property rights over the material you provide to the Service. </li>
            <li>Your profile and materials uploaded remain yours. </li>
            <li>However, by adding users to your account, you agree to allow others to view and share your Content. </li>
          </ol>

          <h3>General Conditions</h3>
          <ol>
            <li>You understand that SureSure uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service, and has no control over such hardware, software, networking, storage, and related technology.  </li>
            <li>You may not modify, adapt or hack the Service or modify another website or application so as to falsely imply that it is associated with the Service, SureSure, or any other SureSure service. </li>
            <li>You agree not to reproduce, duplicate, copy, sell, resell or exploit any portion of the Service, use of the Service, or access to the Service without the express written permission by SureSure. </li>
            <li>You understand that the technical processing and transmission of the Service, including your Content, may be transferred unencrypted and involve submissions over various networks, and changes to conform and adapt to technical requirements of connecting networks or devices.</li>
            <li>You must not transmit any worms or viruses or any code of a destructive nature.</li>
            <li>SureSure may use your Company Names and Logos in case studies, promotional materials, or other write-ups, unless you request otherwise. </li>
            <li>You expressly understand and agree that SureSure shall not be liable for any direct, indirect, incidental, special, consequential or exemplary damages, including but not limited to, damages for loss of profits, goodwill, use, data or other intangible losses (even if SureSure has been advised of the possibility of such damages), resulting from: </li>
            <ol type='a'>
              <li>he use or the inability to use the service;</li>
              <li>unauthorized access to or alteration of your transmissions or data;</li>
              <li>statements or conduct of any third party on the service; or </li>
              <li>any other matter relating to the service.</li>
            </ol>
            <li>The failure of SureSure to exercise or enforce any right or provision of the Terms of Use shall not constitute a waiver of such right or provision. </li>
            <li>The Terms of Use and the Privacy Policy shall constitute the entire agreement between you and SureSure and govern your use of the Service, superseding any prioragreements between you and SureSure on the subjects dealt with herein (including, but not limited to, any prior versions of the Terms of Use and Privacy Policy).</li>
            <li>Terms of Use and the Privacy Policy shall be interpreted under the laws of the Republic of South Africa, and shall be adjudicated on in a South African court with jurisdiction.</li>
          </ol>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default TermsAndConditions
