import React from 'react'
import './Story.css'
import Teammember01 from 'assets/images/Teammember01.png'
import Teammember02 from 'assets/images/Teammember02.png'
import Teammember03 from 'assets/images/Teammember03.png'
import Teammember04 from 'assets/images/Teammember04.png'
import Teammember05 from 'assets/images/Teammember05.png'
import Teammember06 from 'assets/images/Teammember06.png'
import Gottabe from 'assets/images/gottabe.svg'

import Button from 'components/Button/Button'

import Footer from 'components/Footer/Footer'

const Story = () => {
  return (
    <>
      <div className='Story-container'>
        <div className='Story-hero'>
          <div className='Summary'>
            <h2> Sh^t! Damn! F@*K!</h2>
          </div>
          <div className='Summary'>
            <div className='subtitle'>
              <h4> We know the feeling.</h4>
              <h4 className='highlight'> Maybe we can help.</h4>
            </div>
            <p>
              Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's
              standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.
              It has survived.1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop
              publishing software like Aldus PageMaker including versions.1960s with the release of Letraset sheets containing
              Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions.
              1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software
              like Aldus PageMaker including versions.1960s with the release of Letraset sheets containing.
            </p>
          </div>
        </div>
      </div>
      <div className='Ourstory'>

        <div className=''>
          <h2>Web application available for desktop and mobile</h2>
          <Button className='ourstoryButton mainButton'>Login</Button>
          <Button className='ourstoryButton subButton'>Signup</Button>
        </div>
        <img
          alt=''
          className='Gottabe'
          src={Gottabe} />

      </div>

      <div className='Suresure-owners'>
        <h2> Meet the team </h2>
        <div className='Team-suresure-gallery'>
          <div >
            <img
              alt=''
              src={Teammember01} />
            <h3 > Harrison Phillips </h3>
            <p>Lost R76 677</p>
          </div >
          <div >
            <img
              alt=''
              src={Teammember02} />
            <h3> Thakurjeet Bharwana </h3>
            <p > Lost R120 000</p>
          </div >
          <div >
            <img
              alt=''
              src={Teammember03} />
            <h3 > Marama Petera </h3>
            <p > Lost R5 000 </p>
          </div >
          <div >
            <img
              alt=''
              src={Teammember04} />
            <h3> Valdemar Forsberg </h3>
            <p > Marketing Manager </p>
          </div>
          <div>
            <img
              alt=''
              src={Teammember05} />
            <h3> Emily James </h3>
            <p> Lost R780 000 </p>
          </div>
          <div >
            <img
              alt=''
              src={Teammember06} />
            <h3> John Clarke </h3>
            <p > Lost R80 000 </p>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Story
