import React from 'react'
import {
  FaFacebook,
  FaTwitter,
  FaInstagram
} from 'react-icons/fa'
import styles from './Footer.module.scss'

const Footer = () => {
  return (
    <footer className={styles.wrapper}>
      <div className={styles.container} >
        <ul className={styles.links} >
          <li><a href='/#'>Help</a></li>
          <li><a href='/about'>About Us</a></li>
          <li><a href='/contact'>Contact Us</a></li>
          <li><a href='/legal/privacy'>Privacy</a></li>
          <li><a href='/legal/terms-and-conditions'>T's &amp; C's</a></li>
        </ul>
        <div className={styles.copyright}>SureSure Ltd 2019</div>
        <ul className={`${styles.links} ${styles.socialMedia}`} >
          <li><a href='http://facebook.com/getsuresure' ><FaFacebook /></a></li>
          <li><a href='http://twitter.com/getsuresure' ><FaTwitter /></a></li>
          <li><a href='http://instagram.com/getsuresure' ><FaInstagram /></a></li>
        </ul >
      </div>
    </footer>
  )
}

export default Footer
