import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Close } from '@material-ui/icons'

import styles from './Input.module.scss'
import { composeClasses } from 'lib/utils'

const RenderImage = ({ name, src }) => (
  <img
    alt={name}
    className={styles.filePreviewImage}
    src={src}
  />
)

RenderImage.propTypes = {
  name: PropTypes.string.isRequired,
  src: PropTypes.string.isRequired
}

const RenderFilePreview = ({ file }) => {
  const [imageSrc, setImageSrc] = useState('')

  if (!file) return null

  // If the file has a previous src (previously uploaded files), use that instead
  if (file.src) {
    return (
      <RenderImage
        name={file.name}
        src={file.src} />
    )
  }

  const isImage = file.type && file.type.includes('image/')
  if (!isImage) return file.name

  const reader = new window.FileReader()

  reader.addEventListener('load', event => {
    setImageSrc(reader.result)
  })

  reader.readAsDataURL(file)

  return (
    <RenderImage
      name={file.name}
      src={imageSrc} />
  )
}

RenderFilePreview.propTypes = {
  file: PropTypes.object.isRequired
}

/**
 * A file input
 * Note: This component is optimized for uploading multiple/single images and should be tested carefully if used for plain files (additional styling will likely be needed for non-image multiple uploads)
 */
export const FileInput = ({ className, defaultValues, handleDeletedFile, multiple, name, children, resetFiles, updater, ...rest }) => {
  const filteredDefaultValues = Array.isArray(defaultValues)
    ? defaultValues.filter(i => i)
      .map((fileSrc) => {
        return {
          isDefaultValue: true, // Flag to know if the file is a default value or not (to determine if file deletion should be handled or not)
          name: fileSrc,
          src: fileSrc
        }
      })
    : []
  const [fileList, setFileList] = useState(filteredDefaultValues)
  // Files in the default value that have been removed
  const [deletedFiles, setDeletedFiles] = useState([])

  // COULD BE BETTER IMPLEMENTED: Allow a parent component to reset the file list by setting a flag
  useEffect(() => {
    if (resetFiles) {
      setFileList([])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [resetFiles])

  // Call the updater function when the fileList is changed
  useEffect(() => {
    updater(fileList, deletedFiles)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, deletedFiles])

  const hasUploadedFiles = fileList.length > 0
  const showReceiptInput = !hasUploadedFiles && !multiple
  const showInput = showReceiptInput || multiple

  return (
    <div className={styles.fileInput}>
      {
        <ul className={styles.fileList}>
          <>
            {
              hasUploadedFiles && (
                fileList.map(file => (
                  <li
                    className={styles.fileListItem}
                    key={file.name}
                  >
                    <span
                      className={styles.fileListItemDeleteIcon}
                      onClick={() => {
                        // On click, remove the item from the file list
                        const filteredItems = fileList.filter(i => file.name !== i.name)

                        if (file.isDefaultValue) {
                          setDeletedFiles([...deletedFiles, file.name])

                          if (handleDeletedFile && typeof handleDeletedFile === 'function') {
                            handleDeletedFile(file)
                          }
                        }

                        !file.isDefaultValue && setFileList(filteredItems || [])
                      }}
                    >
                      <Close />
                    </span>

                    <RenderFilePreview file={file} />
                  </li>
                ))
              )
            }
          </>

          { showInput &&
          <li className={styles.fileListItemLabel}>
            <label
              className={composeClasses(
                styles.fileContainer,
                className,
                hasUploadedFiles && styles.fileContainerWithUploadedFiles
              )}
              htmlFor={name}
            >

              {hasUploadedFiles ? '+' : children}

              <input
                multiple={multiple}
                name={name}
                onChange={event => {
                  event.persist()
                  const { files } = event.target

                  if (files.length < 1) {
                    return
                  }

                  // For non-multiple uploads, replace the file list with the single selected item
                  if (!multiple) {
                    setFileList([files[0]])
                    return
                  }

                  const dedupedFiles = []

                  // Prevent duplicate files from being added
                  Array.from(files).forEach(file => {
                    const existingFile = fileList.find(i => i.name === file.name && i.lastModified === file.lastModified)

                    if (!existingFile) {
                      dedupedFiles.push(file)
                    }
                  })

                  setFileList([
                    ...fileList,
                    ...dedupedFiles
                  ])
                }}
                type='file'
                {...rest} />
            </label>
          </li>
          }

        </ul>
      }

    </div>

  )
}

FileInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValues: PropTypes.array,
  handleDeletedFile: PropTypes.func,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  resetFiles: PropTypes.bool,
  updater: PropTypes.func
}

export default FileInput
