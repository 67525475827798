/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'

import styles from './Appbar.module.scss'

import history from 'lib/history'
import { AuthContext } from 'context'
import { Link } from 'react-router-dom'
import Logo from '../../assets/images/logo.svg'
import { logoutHandler } from 'containers/Auth/auth.service'

import BurgerMenu from 'components/Nav/BurgerMenu'

import IconButton from '@material-ui/core/IconButton'
import profile from '../../assets/images/icons/profile.svg'
import {
  FaUserCircle,
  FaSignOutAlt,
  FaChevronDown
} from 'react-icons/fa'
import MenuItem from '@material-ui/core/MenuItem'
import Menu from '@material-ui/core/Menu'

import Button from '../Button/Button'

const loginHandler = () => history.push('/auth/login')

const Appbar = ({ renderIcon, title }) => {
  const userData = useContext(AuthContext)

  const [anchorEl, setAnchorEl] = React.useState(null)

  const isMenuOpen = Boolean(anchorEl)

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
      keepMounted
      onClose={handleMenuClose}
      open={isMenuOpen}
      transformOrigin={{ horizontal: 'right', vertical: 'top' }}
    >
      {userData ? (
        <div className={styles.menu}>
          <Link
            onClick={handleMenuClose}
            to='/app/account'>
            <MenuItem>
              <span className={styles.icon}>
                <FaUserCircle />
              </span>
              <span className={styles.title}>Profile</span>
            </MenuItem>
          </Link>
          <MenuItem
            id='logout'
            onClick={event => handleMenuClose(event)}>
            <span className={styles.icon}>
              <FaSignOutAlt />
            </span>
            <span className={styles.title}>Logout</span>
          </MenuItem>
        </div>
      ) : ''}
    </Menu>
  )

  function handleMenuClose (event) {
    setAnchorEl(null)
    event.stopPropagation()

    if (event.currentTarget.id === 'login') {
      loginHandler()
    } else if (event.currentTarget.id === 'logout') {
      logoutHandler()
    }
  }

  function handleProfileMenuOpen (event) {
    setAnchorEl(event.currentTarget)
  }

  const burgerMenuClass = userData ? styles.displayLoggedIn : styles.displayLoggedOut

  return (
    <>
      <Helmet>
        <title>{title && `${title} | `} SureSure</title>
      </Helmet>
      <div className={styles.outer}>
        <nav className={styles.container}>
          {typeof renderIcon === 'function' && renderIcon()}
          <BurgerMenu
            isLoggedIn={userData}
          />
          <span className={styles.logo}>
            <Link to={userData ? '/app/dashboard' : '/'}>
              <img
                alt={title}
                src={Logo} />
            </Link>
          </span>
          <span>
            <div
              className={burgerMenuClass}>
              <IconButton
                aria-haspopup='true'
                aria-label='Account of current user'
                className={styles.iconButton}
                color='inherit'
                edge='end'
                onClick={handleProfileMenuOpen}
                size='medium'>
                <img
                  className={styles.icon}
                  src={profile} />
              </IconButton>
            </div>
            {userData ? (
              <Button
                className={styles.dashboardDropdown}
                onClick={handleProfileMenuOpen}
              >
                <span>My Account</span>
                <FaChevronDown />
              </Button>
            ) : (
              <Button
                className={styles.myAccountButton}
                color='primary'
                onClick={event => loginHandler()}
              >
                <span>Login</span>
              </Button>
            )}
          </span>
        </nav>
      </div>
      {renderMenu}
    </>
  )
}

Appbar.propTypes = {
  renderIcon: PropTypes.func,
  title: PropTypes.string.isRequired
}

export default Appbar
