import { FormMessageTypes } from 'components/Forms/Input'
import firebase from 'services/firebase'

/**
 * Resend Email Verification
 * @param event
 * @param setDispatch
 * @param userData
 */
export const handleResendVerification = (event, setDispatch) => {
  event.preventDefault()

  const user = firebase.auth().currentUser

  if (!user) return

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  user
    .sendEmailVerification()
    .then(response => {
      setDispatch({
        loading: false,
        message: 'Your verification email has been sent',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setDispatch({
        loading: false,
        message:
          err.message ||
          'Unknown error while trying to resend verification email',
        type: FormMessageTypes.error
      })
    })
}
