import React, { useState } from 'react'
import IconButton from '@material-ui/core/IconButton'
import { Visibility, VisibilityOff } from '@material-ui/icons'

import { Input } from 'components/Forms/Input'

const PasswordField = props => {
  const [hidePassword, setHidePassword] = useState(false)

  return (
    <>
      <Input
        type={hidePassword ? 'input' : 'password'}
        {...props} />
      <IconButton
        color='inherit'
        edge='end'
        onClick={() => {
          setHidePassword(!hidePassword)
        }}
        size='medium'
        style={{
          bottom: 0,
          cursor: 'pointer',
          margin: 'auto',
          position: 'absolute',
          right: 0
        }}
        tabIndex={-1}
      >
        {hidePassword ? <VisibilityOff /> : <Visibility />}
      </IconButton>
    </>
  )
}

export default PasswordField
