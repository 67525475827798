import React, { useEffect, useState } from 'react'
import { Route, Router } from 'react-router-dom'
import reqClient from './lib/reqClient'
import { ApolloProvider } from 'react-apollo'

// Pages
import Contact from 'containers/Marketing/Contact/Contact'

import Home from 'containers/Marketing/Home/Home'

import firebase from 'services/firebase'
import { AuthContext, ModalContext } from './context'
import { GET_ACCOUNT } from 'config/graphqlDefinitions'
import config from 'config'

import history from 'lib/history'
import { localStore } from 'lib/utils'
import styles from './App.module.scss'
import Appbar from 'components/Appbar/Appbar'

import Modal from 'components/Modal'
import AppRoutes, { AuthRoutes } from 'components/AppRoute'
import Loader from 'components/Loader/Loader'
import TermsAndConditions from 'containers/Legal/TermsAndConditions'
import Privacy from 'containers/Legal/Privacy'

const App = () => {
  // This makes sure that the app and all it's components 'waits' for Firebase Auth to be loaded before rendering
  // Preventing flashes or flickering from authenticated to non authenticated
  const [appLoading, setAppLoading] = useState(true)

  //  Flag that toggles the states based on whether Firebase has loaded yet
  //  Authenticated user retured from Firebase
  const [user, setUser] = useState()
  //  Handles modal state
  const [modal, setModal] = useState({
    visible: false // If the modal should be shown or not
  })

  //  Flag that toggles if Firebase Authentication fails
  const [firebaseError, setFirebaseError] = useState(false)

  useEffect(() => {
    try {
      firebase.performance()

      // set sigin/signout event observer
      firebase.auth().onAuthStateChanged(async authUser => {
        if (authUser) {
          // Check if the user's email is unverified
          if (!authUser.emailVerified) {
            setAppLoading(false)
            return
          }

          const {
            data: { getAccountDetails }
          } = await reqClient
            .query({
              fetchPolicy: 'no-cache',
              query: GET_ACCOUNT
            })
            .catch(err => {
              console.log('Error getting account details => ', err)

              return { data: {} }
            })

          authUser.userInfo = getAccountDetails

          setAppLoading(false)
          setUser(authUser)

          // Persist user details to localStorage
          localStore.set(config.storageKeys.userInfo, authUser, true)
        } else {
          setAppLoading(false)
          setUser(null)
          localStore.remove(config.storageKeys.userInfo)
        }
      })
    } catch (error) {
      setFirebaseError(true)
      setAppLoading(false)
    }
  }, [])

  if (firebaseError) {
    return (
      <div>
        <h3>Authentication Error</h3>
        {process.env.NODE_ENV === 'development' && (
          <p>
            Ensure the Firebase credentials in your `.env` are present and
            correct
          </p>
        )}
      </div>
    )
  }

  if (appLoading) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }

  return (
    <React.StrictMode>

      <div className={styles.wrapper}>
        <AuthContext.Provider value={user}>
          <ApolloProvider client={reqClient}>
            <ModalContext.Provider
              value={{
                ...modal,
                setModalState: params => setModal(params)
              }}
            >
              <Router history={history}>
                <Appbar title='' />

                {modal.visible && <Modal {...modal} />}

                <AppRoutes />
                <AuthRoutes />

                <Route
                  component={Home}
                  exact
                  path='/' />
                <Route
                  component={Contact}
                  exact
                  path='/contact' />
                <Route
                  component={TermsAndConditions}
                  exact
                  path='/legal/terms-and-conditions'
                />
                <Route
                  component={Privacy}
                  exact
                  path='/legal/privacy'
                />
              </Router>
            </ModalContext.Provider>
          </ApolloProvider>
        </AuthContext.Provider>
      </div>
    </React.StrictMode>
  )
}

export default App
