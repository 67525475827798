import React from 'react'
import PropTypes from 'prop-types'
import Badge from '../../components/Badge/Badge'
import styles from './ItemStatus.module.scss'

const ItemStatus = ({ statuses }) => {
  return (
    <div className={styles.status}>
      {statuses.map(({ title, type }, key) => (
        type &&
          <Badge
            key={key}
            title={title}
            type={type} />
      ))}
    </div>
  )
}

ItemStatus.propTypes = {
  statuses: PropTypes.array.isRequired
}

export default ItemStatus
