import React, { useState, useContext } from 'react'
import { Done, BlockOutlined, Edit } from '@material-ui/icons'

import { FormGroup, FormMessage, Input, Label } from 'components/Forms/Input'
import { AuthContext, ModalContext } from 'context'
import { composeClasses } from 'lib/utils'
import Button from 'components/Button/Button'
import Badge from 'components/Badge/Badge'
import styles from './TeamList.module.scss'
import PropTypes from 'prop-types'
import { MdClear } from 'react-icons/md'

import {
  handleSendInvite,
  handleAccountUpdate
} from 'containers/Account/account.service'

//  TODO: add global layout styles in seperate style sheet i.e. box and title style used on the login box
import authStyles from '../../containers/Auth/auth.module.scss'

export const HeaderItem = ({ className, title }) => (
  <div className={composeClasses(styles.item, styles[className])}>{title}</div>
)

HeaderItem.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
}

export const Item = ({
  uid,
  name,
  email,
  active,
  disabled,
  emailVerified,
  setDispatch
}) => {
  const currentUser = useContext(AuthContext)
  const { setModalState } = useContext(ModalContext)
  const [isDisabled, setIsDisabled] = useState(disabled)

  const userInfo = currentUser && currentUser.userInfo
  const isOwner = userInfo && Boolean(userInfo.companyRole === 'Owner')

  const showEditInviteModal = () => {
    setModalState({
      component: (props) => <EditInvite
        email={email}
        {...props} />,
      visible: true
    })
  }

  return (
    <div
      className={styles.row}
      data-key={uid}>
      <div className={styles.item}>{name || 'Waiting for Fullname'}</div>
      <div className={composeClasses(styles.item, styles.email)}>{email}</div>

      <div className={composeClasses(styles.item, styles.badgeContainer)}>
        {!isDisabled && emailVerified ? (
          <Badge
            title='Active'
            type='success' />
        ) : (
          <Badge
            title='Inactive'
            type='error' />
        )}
        <Badge
          title={emailVerified ? 'Verified' : 'Unverified'}
          type={emailVerified ? 'success' : 'error'}
        />
      </div>

      <div className={composeClasses(styles.item, styles.action)}>
        {isOwner &&
          uid &&
          uid !== currentUser.uid &&
          (isDisabled ? (
            <Done
              fontSize='default'
              onClick={(e) =>
                handleAccountUpdate(
                  e,
                  { isDisabled, uid },
                  setDispatch,
                  setIsDisabled
                )
              }
              titleAccess='Activate User'
            />
          ) : (
            <BlockOutlined
              fontSize='default'
              onClick={(e) =>
                handleAccountUpdate(
                  e,
                  { isDisabled, uid },
                  setDispatch,
                  setIsDisabled
                )
              }
              titleAccess='Deactivate User'
            />
          ))}
        {!uid && (
          <div>
            <Edit
              fontSize='default'
              onClick={showEditInviteModal}
              titleAccess='Resend Invite'
            />
          </div>
        )}
      </div>
    </div>
  )
}

Item.propTypes = {
  active: PropTypes.bool,
  disabled: PropTypes.bool,
  email: PropTypes.string,
  emailVerified: PropTypes.bool,
  name: PropTypes.string,
  setDispatch: PropTypes.func,
  uid: PropTypes.string
}

const EditInvite = ({ closeFn, email }) => {
  return (
    <div className={styles.editInvite}>
      <header className={styles.editInviteHeader}>
        Edit Invite
        <MdClear
          color='#acacac'
          onClick={closeFn}
          size={24} />
      </header>
      <div className={authStyles.container}>
        <InviteForm
          closeFn={closeFn}
          email={email} />
      </div>
    </div>
  )
}

EditInvite.propTypes = {
  closeFn: PropTypes.func,
  email: PropTypes.string
}

const InviteForm = ({ email, closeFn }) => {
  const [inviteEmail, setInviteEmail] = useState(email)
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <form onSubmit={(e) => handleSendInvite(e, setDispatch)}>
      <FormGroup>
        <Label htmlFor='email'>Email Address</Label>
        <Input
          id='email'
          name='email'
          onChange={(e) => setInviteEmail(e.target.value)}
          required
          type='email'
          value={inviteEmail}
        />
      </FormGroup>

      <FormMessage
        message={dispatch.message}
        type={dispatch.type} />

      <div className={styles.formActions}>
        <Button
          className={styles.cancelButton}
          onClick={closeFn}
          type='button'>
          Cancel
        </Button>

        <Button
          className={styles.submitButton}
          loading={dispatch.loading}
          type='submit'
        >
          Resend Invite
        </Button>
      </div>
    </form>
  )
}

InviteForm.propTypes = {
  closeFn: PropTypes.func,
  email: PropTypes.string
}

export const List = ({ headerItems, listItems }) => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <div className={styles.list}>
      <FormMessage
        message={dispatch.message}
        type={dispatch.type} />

      {listItems && listItems.length !== 0 && (
        <>
          {headerItems && headerItems.length !== 0 && (
            <div className={composeClasses(styles.row, styles.header)}>
              {headerItems.map(({ className, title }, key) => (
                <HeaderItem
                  className={className}
                  key={key}
                  title={title} />
              ))}
            </div>
          )}
          {listItems.map(
            (
              {
                accepted,
                email,
                forEmail,
                firstName,
                lastName,
                uid,
                emailVerified,
                disabled
              },
              key
            ) =>
              forEmail ? (
                <Item
                  active={accepted || true}
                  email={forEmail}
                  key={uid || key}
                  setDispatch={setDispatch}
                  uid={uid}
                />
              ) : (
                <Item
                  disabled={disabled}
                  email={email}
                  emailVerified={emailVerified}
                  key={uid || key}
                  name={`${firstName} ${lastName}`}
                  setDispatch={setDispatch}
                  uid={uid}
                />
              )
          )}
        </>
      )}
    </div>
  )
}

List.propTypes = {
  headerItems: PropTypes.array,
  listItems: PropTypes.array
}
