import React, { useState } from 'react'
import { MdArrowBack } from 'react-icons/md'

import { FormGroup, FormMessage, Input, Label } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import history from 'lib/history'
import { handleSendInvite } from '../account.service'

//  TODO: add global layout styles in seperate style sheet i.e. box and title style used on the login box
import authStyles from '../../Auth/auth.module.scss'

const Invite = () => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Invite People</span>
      </div>
      <div className={authStyles.container}>
        <form onSubmit={e => handleSendInvite(e, setDispatch)}>
          <FormGroup>
            <Label htmlFor='email'>Email Address</Label>
            <Input
              id='email'
              name='email'
              required
              type='email' />
          </FormGroup>

          <FormMessage
            message={dispatch.message}
            type={dispatch.type} />

          <Button
            loading={dispatch.loading}
            type='submit'>
            Send Invite
          </Button>
        </form>
      </div>
    </>
  )
}

export default Invite
