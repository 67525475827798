
export const serializeFromElement = (element) => {
  const payload = {}

  if (!element) {
    return payload
  }

  const inputs = element.querySelectorAll('input, select, textarea')

  inputs.forEach(({
    checked, name, type, value
  }) => {
    if (!name) return

    switch (type) {
      // Converts a checkbox to an array
      case 'checkbox': {
        const checkedItems = payload[name] || []

        if (checked) {
          checkedItems.push(value)
        }

        payload[name] = checkedItems
        break
      }

      case 'file':
        // Files should be handled by their file handler instead
        break

      default:
        payload[name] = value
        break
    }
  })

  return payload
}

export const serializeForm = (event) => {
  const inputs = event.target

  return serializeFromElement(inputs)
}
