import React from 'react'
import Footer from 'components/Footer/Footer'

import styles from './Legal.module.scss'

const Privacy = () => {
  return (
    <>
      <div className={styles.container}>
        <h2>Privacy</h2>
        <p className={styles.content}>
          posuere rutrraesent et blandit elit.Morbi tempor blandit ligula, at
          venenatis est faucibus nec.Sed imperdiet faucibus lorem, ac
          solliraesent et blandit elit.Morbi tempor blandit ligula, at venenatis
          est faucibus nec.posuere rutrum.Praesent et blandit elit.Morbi tempor
          blandit ligula, at venenatis est faucibus nec.Sed imperdiet faucibus
          lorem, ac solliraesent et blandit eorbi tempor blandit ligula, at
          venenatis est faucibus blandit elit.Morbi tempor blandit ligula, at
          venenatis est faucibus nec.Sed imperdiet faucibus lorem, ac
          solliraesent et blandit empor blandit ligula, at venenatis est
          faucibus nposuere rutrum.Praesent et blandit elit.Morbi tempor blandit
          ligula, at venenatis est faucibus nec.Sed imperdiet faucibus lorem, ac
          solliraesent et blan venenatis est faucibus esent et blandit
          elit.Morbi tempor blandit ligula, at venenatis est faucibus nec.Sed
          imperdiet faucibus lorem, ac solliraesent et blandit elit.Morbi tempor
          blandit liguenenatis est faucibus venenatis est faucibus nec.Sed
          imperdiet faucibus loremit ligula, at venenatis est faucibus
          nec.posuere rutrum.Praesent et blandit elit.Morbi tempor blandit
          ligula, at venenatis est faucib ed imperdiet faucibus lorem, ac
          solliraesent et blandit elit.Morbi tempor blandit ligula, at venenatis
          est faucibus blandit elit.Morbi tempor blandit ligula, at venenatis
          est faucibus nec.Sed imperdiet faucibus lorem, ac olliraesea, at
          venenatis est faucibus nposuere rutrum.Praesent et blandit elit.Morbi
          tempor blandit ligula, at venenatis est faucibus nec.Sed imperdiet
          faucibus lorem, ac olliraesent et blandrbi tempor blandit ligula, at
          venenatis est faucibus esent et blandit elit.Morbi tempor blandit
          ligulvenenatis est faucibus nec.Sed imperdiet faucibus lorelliraesent
          landit elit.Morbi tempor blandit ligula, at venenatis est faucibus
          venenatis est faucibus nec.Sed imperdiet faucibus lorelliraesent et
          blandit elit.Morbi tempor blandit ligula, at venenatis esaucb
        </p>
      </div>
      <Footer />
    </>
  )
}

export default Privacy
