import { ApolloClient } from 'apollo-client'
import { createUploadLink } from 'apollo-upload-client'
import { onError } from 'apollo-link-error'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { ApolloLink, Observable } from 'apollo-link'
import history from 'lib/history'

import firebase from 'services/firebase'
import config from '../config'
import { logoutHandler } from 'containers/Auth/auth.service'

const request = async operation => {
  const { currentUser } = firebase.auth()

  if (!currentUser) return

  const token = await currentUser.getIdToken()

  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : ''
    }
  })
}

const requestLink = new ApolloLink(
  (operation, forward) =>
    new Observable(observer => {
      let handle
      Promise.resolve(operation)
        .then(oper => request(oper))
        .then(() => {
          handle = forward(operation).subscribe({
            complete: observer.complete.bind(observer),
            error: observer.error.bind(observer),
            next: observer.next.bind(observer)
          })
        })
        .catch(observer.error.bind(observer))

      return () => {
        if (handle) handle.unsubscribe()
      }
    })
)

const reqClient = new ApolloClient({
  cache: new InMemoryCache(),

  link: ApolloLink.from([
    onError(({ networkError }) => {
      const authErrors = networkError && networkError.result && networkError.result.errors

      if (!authErrors) return

      const isAuthError = authErrors.find(err => err.extensions.code === 'UNAUTHENTICATED')

      if (isAuthError) {
        logoutHandler(true)
        return
      }

      const apiErrorMessages = config.api.errorMessages

      const isUnverifiedError = authErrors.find(err => err.message === apiErrorMessages.unverified)

      if (isUnverifiedError) {
        // Redirect to verification page
        history.push('/auth/verification-required')
      }
    }),

    requestLink,

    createUploadLink({ uri: config.api.root })
  ])
})

export default reqClient
