import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import history from 'lib/history'

import { FormGroup, Label, Input, FormMessage } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import PasswordField from 'components/Forms/PasswordField'
import authStyles from '../auth.module.scss'
import { handleSignup } from '../auth.service'
import { AuthCTA } from '../auth.shared'
import { MdArrowBack } from 'react-icons/md'

const getUrlParams = (search = ``) => {
  const hashes = search.slice(search.indexOf(`?`) + 1).split(`&`)
  return hashes.reduce((acc, hash) => {
    const [key, val] = hash.split(`=`)
    return {
      ...acc,
      [key]: decodeURIComponent(val)
    }
  }, {})
}

export const Personal = ({ match }) => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })
  const inviteCode = match.params && match.params.inviteCode
  const inviteEmail = inviteCode
    ? getUrlParams(window.location.search) &&
      getUrlParams(window.location.search).email
    : false

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Create a Personal Account</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <form onSubmit={e => handleSignup(e, setDispatch, inviteCode)}>
            <FormGroup>
              <Label htmlFor='fname'>First Name</Label>
              <Input
                id='fname'
                name='fname'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='lname'>Last Name</Label>
              <Input
                id='lname'
                name='lname'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='email'>Email Address</Label>
              {inviteEmail ? (
                <Input
                  id='email'
                  name='email'
                  readOnly
                  required
                  type='email'
                  value={inviteEmail}
                />
              ) : (
                <Input
                  id='email'
                  name='email'
                  required
                  type='email' />
              )}
            </FormGroup>

            <FormGroup>
              <Label htmlFor='phone'>Cellphone Number</Label>
              <Input
                id='phone'
                name='phone'
                required
                type='number' />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='password'>Password</Label>
              <PasswordField
                id='password'
                name='password'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='confirmPassword'>Confirm Password</Label>
              <PasswordField
                id='confirmPassword'
                name='confirmPassword'
                required
              />
            </FormGroup>

            <FormMessage
              message={dispatch.message}
              type={dispatch.type} />

            <Button
              loading={dispatch.loading}
              type='submit'>
              Signup
            </Button>
          </form>
        </main>
        <hr className={authStyles.line} />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/login'>
              <Button className={authStyles.grayBtn}>Login</Button>
            </Link>
          )}
          title='Have an account already?'
        />
      </div>
    </>
  )
}

export const Business = () => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Create a Business Account</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <form onSubmit={e => handleSignup(e, setDispatch)}>
            <FormGroup>
              <Label htmlFor='businessName'>Business Name</Label>
              <Input
                id='businessName'
                name='businessName'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='fname'>First Name</Label>
              <Input
                id='fname'
                name='fname'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='lname'>Last Name</Label>
              <Input
                id='lname'
                name='lname'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='email'>Email Address</Label>
              <Input
                id='email'
                name='email'
                required
                type='email' />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='phone'>Cellphone Number</Label>
              <Input
                id='phone'
                name='phone'
                required
                type='number' />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='password'>Password</Label>
              <PasswordField
                id='password'
                name='password'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='confirmPassword'>Confirm Password</Label>
              <PasswordField
                id='confirmPassword'
                name='confirmPassword'
                required
              />
            </FormGroup>

            <FormMessage
              message={dispatch.message}
              type={dispatch.type} />

            <Button
              loading={dispatch.loading}
              type='submit'>
              Signup
            </Button>
          </form>
        </main>
        <hr className={authStyles.line} />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/login'>
              <Button className={authStyles.grayBtn}>Login</Button>
            </Link>
          )}
          title='Have an account already?'
        />
      </div>
    </>
  )
}

Personal.propTypes = {
  match: PropTypes.object
}
