/* eslint-disable jsx-a11y/alt-text */
import React, { useContext } from 'react'
import PropTypes from 'prop-types'
import styles from './NavItems.module.scss'
import { Link } from 'react-router-dom'

import { AuthContext } from 'context'
import { composeClasses } from 'lib/utils'
import history from 'lib/history'

import archiveItem from '../../assets/images/icons/archiveItem.svg'
import team from '../../assets/images/icons/team.svg'
import dashboard from '../../assets/images/icons/dashboard.svg'
import user from '../../assets/images/icons/user.svg'

import List from 'components/List/List'
import {
  FaTimes,
  FaUsers,
  FaUserCircle
} from 'react-icons/fa'

const getSideMenuItems = (isBusinessUser) => ({
  loggedOut: [
    {
      canClick: true,
      icon: <FaUserCircle />,
      key: 'login',
      path: '/auth/login',
      title: 'Login'
    },
    {
      canClick: true,
      icon: <FaUsers />,
      key: 'signup-business',
      path: '/auth/signup/business',
      title: 'Sign up Business'
    },
    {
      canClick: true,
      icon: <img
        className={styles.AppIcon}
        src={user} />,
      key: 'signup-personal',
      path: '/auth/signup/personal',
      title: 'Sign up Personal'
    }
  ],
  loggedin: [
    {
      canClick: true,
      icon: <img
        className={styles.AppIcon}
        src={dashboard} />,
      key: 'dashboard',
      path: '/app/dashboard',
      title: 'Dashboard'
    },
    {
      canClick: true,
      icon: <img
        className={styles.AppIcon}
        src={archiveItem} />,
      key: 'archive',
      path: '/app/item/archive',
      title: 'Archived Items'
    },
    {
      canClick: isBusinessUser || false,
      icon: <img
        className={styles.AppIcon}
        src={team} />,
      key: 'members',
      path: '/app/account/team',
      title: 'Manage Members'
    }
  ]
})

const isSelected = path => {
  return window.location.pathname === path
}

const NavItems = ({ toggleMenu, show, customStyles = null }) => {
  const userData = useContext(AuthContext)
  const userInfo = userData && userData.userInfo
  const isBusinessUser = userInfo && Boolean(userInfo.company)

  let sideMenuClass = styles.sideMenu

  function getClass (className) {
    return customStyles && customStyles[className] ? customStyles[className] : styles[className]
  }

  if (show) {
    sideMenuClass = composeClasses(getClass('sideMenu'), styles.open)
  }

  return (
    <>
      <nav className={composeClasses(sideMenuClass)}>
        <button
          className={getClass('closeNav')}
          onClick={event => {
            if (toggleMenu) toggleMenu(event)
          }}
        >
          <FaTimes />
        </button>
        <List
          items={userData ? getSideMenuItems(isBusinessUser).loggedin : getSideMenuItems().loggedOut}
          renderItem={item => {
            const selectedClass = isSelected(item.path) ? getClass('selected') : ''
            const inActiveClass = !item.canClick ? getClass('inActiveLink') : ''
            return (
              <Link
                className={composeClasses(selectedClass, inActiveClass, styles.link)}
                onClick={event => {
                  event.preventDefault()
                  if (toggleMenu) toggleMenu(event)
                  history.push(item.path)
                }}
                to={item.path}
              >
                <span className={styles.icon}>{item.icon}</span>
                <span className={getClass('label')}>{item.title}</span>
              </Link>
            )
          }}
        />
      </nav>
    </>
  )
}

NavItems.propTypes = {
  customStyles: PropTypes.object,
  show: PropTypes.bool,
  toggleMenu: PropTypes.func
}

export default NavItems
