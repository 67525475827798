import React from 'react'
import PropTypes from 'prop-types'
import { composeClasses } from 'lib/utils'

import styles from './Pagination.module.scss'

const Pagination = ({
  handlePageChange,
  prevPageCursor,
  currentPageCursor,
  nextPageCursor
}) => {
  const paginationOptions = [
    {
      cursor: null,
      isVisible: !!currentPageCursor,
      name: 'First'
    },
    {
      cursor: prevPageCursor,
      isVisible: !!prevPageCursor,
      name: 'Previous'
    },
    {
      cursor: currentPageCursor,
      isVisible: !!nextPageCursor,
      name: 'Current'
    },
    {
      cursor: nextPageCursor,
      isVisible: !!nextPageCursor,
      name: 'Next'
    }
  ]

  return (
    <div className={styles.pagination}>
      <div className={styles.pageNumbers}>
        {paginationOptions.map(({ cursor, name, isVisible }, i) => {
          return (
            <div
              //   eslint-disable-next-line react/no-array-index-key
              className={composeClasses(
                styles.item,
                currentPageCursor === cursor && styles.presentPage
              )}
              disabled={name === 'Current'}
              key={i}
              onClick={() => handlePageChange(cursor)}
              onKeyDown={() => handlePageChange(cursor)}
              role='button'
              style={{ display: isVisible ? 'block' : 'none' }}
              tabIndex={0}
            >
              {name}
            </div>
          )
        })}
      </div>
    </div>
  )
}

Pagination.propTypes = {
  currentPageCursor: PropTypes.string.isRequired,
  handlePageChange: PropTypes.func.isRequired,
  nextPageCursor: PropTypes.string.isRequired,
  prevPageCursor: PropTypes.string.isRequired
}

export default Pagination
