import firebase from 'services/firebase'
import history from 'lib/history'
import { FormMessageTypes } from 'components/Forms/Input'
import reqClient from 'lib/reqClient'
import { CREATE_ACCOUNT } from 'config/graphqlDefinitions'
import { getParameterByName } from 'lib/utils'
import config from 'config'
/**
 * Login a user
 * @param {*} event
 * @param {*} setDispatch
 */
export const handleLogin = (event, setDispatch) => {
  event.preventDefault()

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  const email = document.getElementById('email').value
  const password = document.getElementById('password').value

  firebase
    .auth()
    .signInWithEmailAndPassword(email, password)
    .then((user) => {
      // Set the user data in the local storage to facilitate faster checking rathen than waiting for Firebase to load the user
      localStorage.setItem(config.storageKeys.userInfo, JSON.stringify(user.user))

      let redirectUrl = '/app/dashboard'
      const searchParams = new URLSearchParams(window.location.search)

      if (searchParams.has('to')) {
        redirectUrl = decodeURIComponent(searchParams.get('to'))
      }

      history.push(redirectUrl)
    })
    .catch(err => {
      setDispatch({
        message: err.message || 'Unknown error while signing in',
        type: FormMessageTypes.error
      })
    })
}

/**
 * Signup a new user
 * @param {*} event
 * @param {*} setDispatch
 * @param inviteCode
 */
export const handleSignup = (event, setDispatch, inviteCode) => {
  event.preventDefault()

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  const companyElement = document.getElementById('businessName')
  const companyName = companyElement && companyElement.value
  const email = document.getElementById('email').value
  const firstName = document.getElementById('fname').value
  const lastName = document.getElementById('lname').value
  const password = document.getElementById('password').value
  const phoneNumber = document.getElementById('phone').value
  const confirmPassword = document.getElementById('confirmPassword').value

  if (phoneNumber.length < 10) {
    setDispatch({
      loading: false,
      message: 'Cellphone number must have at least 10 digits',
      type: FormMessageTypes.error
    })
    return
  }

  if (password.length < 6) {
    setDispatch({
      loading: false,
      message: 'Password must be at least 6 characters',
      type: FormMessageTypes.error
    })
    return
  }

  if (password !== confirmPassword) {
    setDispatch({
      loading: false,
      message: 'Passwords do not match',
      type: FormMessageTypes.error
    })
    return
  }

  const userDetails = {
    companyName,
    email,
    firstName,
    inviteCode,
    lastName,
    password,
    phoneNumber
  }

  reqClient
    .mutate({
      mutation: CREATE_ACCOUNT,
      variables: {
        input: userDetails
      }
    })
    .then(response => {
      return firebase.auth().signInWithEmailAndPassword(email, password)
    })
    .then(async ({ user }) => {
      history.push(`${user.emailVerified ? '/app/dashboard' : '/auth/verification-required'}`)
    })
    .catch(err => {
      setDispatch({
        loading: false,
        message: err.message || 'Unknown error while creating account',
        type: FormMessageTypes.error
      })
    })
}

/**
 * Handle resetting a user
 * @param {*} event
 * @param {*} setDispatch
 */
export const handleForgotPassword = (event, setDispatch) => {
  event.persist()
  event.preventDefault()

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  const email = document.getElementById('email').value

  firebase
    .auth()
    .sendPasswordResetEmail(email, {
      url: `${window.location.origin}/auth/login`
    })
    .then(() => {
      event.target.reset()

      setDispatch({
        loading: false,
        message: 'Password reset email sent',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setDispatch({
        loading: false,
        message: err.message || 'Unknown error while resetting password',
        type: FormMessageTypes.error
      })
    })
}

export const logoutHandler = expired => {
  // URL to redirect the user to after logging out
  const redirectTo = encodeURIComponent(window.location.pathname)

  firebase
    .auth()
    .signOut()
    .then(() => {
      const currentPath = window.location.pathname

      localStorage.removeItem(config.storageKeys.userInfo)

      if (expired) return history.push(`/auth/login?expired=true&to=${redirectTo}`)
      if (currentPath === '/auth/verify') return history.push('/auth/login')

      history.push('/auth/login')
    })
    .catch(err => {
      console.log('Unexpected error signing out', err)
    })
}

export const handleConfirmPasswordReset = (e, setDispatch) => {
  e.preventDefault()

  const newPassword = document.getElementById('password').value
  const confirmPassword = document.getElementById('confirmPassword').value
  const actionCode = getParameterByName('actionCode')

  if (newPassword.length < 6) {
    setDispatch({
      loading: false,
      message: 'Password must be at least 6 characters',
      type: FormMessageTypes.error
    })
    return
  }

  if (newPassword !== confirmPassword) {
    setDispatch({
      loading: false,
      message: 'Passwords do not match',
      type: FormMessageTypes.error
    })
    return
  }

  firebase.auth().confirmPasswordReset(actionCode, newPassword).then(response => {
    setDispatch({
      loading: false,
      message: 'Passwords reset successful, login to continue',
      type: FormMessageTypes.success
    })
    setTimeout(() => {
      history.push('/auth/login')
    }, 2000)
  }).catch((err) => {
    setDispatch({
      loading: false,
      message: `Passwords reset failed: ${err.message}`,
      type: FormMessageTypes.success
    })
  })
}

export const handleResetPassword = (setDispatch) => {
  const actionCode = getParameterByName('oobCode')

  firebase.auth().verifyPasswordResetCode(actionCode).then((email) => {
    history.push(`/auth/reset-password?actionCode=${actionCode}`)
  }).catch((err) => {
    setDispatch({
      loading: false,
      message: `Password reset failed. Verification link expired: ${err.message}`,
      type: 'error'
    })
  })
}

export const handleVerifyEmail = () => {
  const actionCode = getParameterByName('oobCode')

  return firebase.auth().applyActionCode(actionCode)
}
