import React, { useState, useEffect } from 'react'
import { useMutation, useApolloClient } from 'react-apollo'

import { serializeForm } from '../../lib/forms'
import { GET_ACCOUNT, UPDATE_ACCOUNT } from 'config/graphqlDefinitions'
import { FormGroup, Label, Input, FormMessage, FormMessageTypes } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import Loader from 'components/Loader/Loader'

export const AccountInformation = () => {
  const [message, setMessage] = useState({
    message: '',
    type: ''
  })
  const [userDataResponse, setUserDataResponse] = useState({
    loading: true,
    userData: {}
  })
  const client = useApolloClient()
  const [updateAccount, { loading }] = useMutation(UPDATE_ACCOUNT)

  useEffect(() => {
    const fetchUserData = async () => {
      const { data, loading } = await client
        .query({
          fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
          query: GET_ACCOUNT
        })

      setUserDataResponse({
        loading,
        userData: data.getAccountDetails
      })
    }

    fetchUserData()
    // eslint-disable-next-line
  }, [])

  if (userDataResponse.loading) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }

  const userData = userDataResponse.userData
  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        event.persist()

        const payload = serializeForm(event)

        updateAccount({
          variables: {
            input: payload
          }
        })
          .then(() => {
            setMessage({
              message: 'Account details updated',
              type: FormMessageTypes.success
            })
          })
          .catch((err) => {
            setMessage({
              message: 'Error updating account details',
              type: FormMessageTypes.error
            })

            console.error('Error updating account details => ', err)
          })
      }}>
      <FormGroup>
        <Label htmlFor='firstName'>First Name</Label>
        <Input
          defaultValue={userData.firstName}
          id='firstName'
          name='firstName'
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='lastName'>Last Name</Label>
        <Input
          defaultValue={userData.lastName}
          id='lastName'
          name='lastName'
          required />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='phoneNumber'>Cellphone Number</Label>
        <Input
          defaultValue={userData.phoneNumber}
          id='phoneNumber'
          name='phoneNumber'
          required
          type='number' />
      </FormGroup>

      {/* <FormGroup>
            <Label htmlFor='password'>Password</Label>
            <PasswordField
              id='password'
              name='password' />
          </FormGroup> */}

      <FormMessage
        message={message.message}
        type={message.type} />

      <Button
        loading={loading}
        type='submit'>
        Update Account
      </Button>
    </form>
  )
}
