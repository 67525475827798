/**
 * Combine a number of classes removing undefined values
 * @param {string} styles A list of classes to compose together
 */
export const composeClasses = (...styles) =>
  styles.filter(item => item).join(' ')

export const getParameterByName = name => {
  var url = new URL(window.location.href)

  return url.searchParams.get(name)
}

export const isNotEmptyArray = arr => Array.isArray(arr) && arr.length > 0

export const formatMoney = num => {
  if (!num) return '0'

  return parseInt(num, 10).toLocaleString()
}

export const formatEmailBody = items => {
  const newLine = escape('\n')
  const giantString = items.reduce(
    (
      acc,
      { name, insurer, insuredValue, serialNumberText, receiptUrl, photoUrls }
    ) => {
      acc += `
${name} 
${insuredValue} 
${insurer.name} 
${photoUrls} 
${serialNumberText} 
${newLine} 
${receiptUrl}
`
      return acc
    },
    ''
  )
  return giantString
}

/**
 * wrapper around the native localStorage to make it more flexible
 */
export const localStore = {
  get: (key, isObject = false) => {
    const value = localStorage.getItem(key)
    let result = null
    if (value) result = isObject ? JSON.parse(value) : value
    return result
  },
  remove: key => {
    localStorage.removeItem(key)
  },
  set: (key, value, isObject = false) => {
    localStorage.setItem(key, isObject ? JSON.stringify(value) : value)
  }
}

export const arrayToObject = arr =>
  arr.reduce((newObject, current) => {
    newObject[current.id] = current
    return newObject
  }, {})

export const objectToArray = obj =>
  Object.values(obj).reduce((newArray, current) => {
    newArray.push(current)
    return newArray
  }, [])
