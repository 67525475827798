import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useApolloClient } from 'react-apollo'
import {
  GET_ACCOUNTS_FOR_COMPANY,
  GET_INVITES_FOR_COMPANY
} from 'config/graphqlDefinitions'
import history from 'lib/history'
import Button from 'components/Button/Button'
import { List } from 'components/TeamList/TeamList'

import styles from '../Account.module.scss'

import Loader from 'components/Loader/Loader'

export const TeamList = ({ teamMembers }) => {
  const headerItems = [
    {
      className: 'name',
      title: 'Name'
    },
    {
      className: 'email',
      title: 'Email Address'
    },
    {
      className: 'status',
      title: ''
    },
    {
      className: 'action',
      title: 'Actions'
    }
  ]

  return (
    <>
      <List
        headerItems={headerItems}
        listItems={teamMembers}
      />
    </>
  )
}

TeamList.propTypes = {
  teamMembers: PropTypes.array
}

export const TeamListPending = ({ invites }) => {
  return (
    <>
      <List
        listItems={invites} />
    </>
  )
}

TeamListPending.propTypes = {
  invites: PropTypes.array
}

const Team = () => {
  const [invitesResponse, setInvitesResponse] = useState({
    error: '',
    invites: [],
    loading: true
  })
  const [teamMembersResponse, setTeamMembersResponse] = useState({
    error: '',
    loading: true,
    teamMembers: []
  })
  const client = useApolloClient()

  useEffect(() => {
    const fetchInvites = async () => {
      const { data, loading, error } = await client
        .query({
          fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
          query: GET_INVITES_FOR_COMPANY
        })

      setInvitesResponse({
        error,
        invites: data.getInvitesForCompany.data,
        loading
      })
    }

    const fetchTeamMembers = async () => {
      const { data, loading, error } = await client
        .query({
          fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
          query: GET_ACCOUNTS_FOR_COMPANY
        })

      setTeamMembersResponse({
        error,
        loading,
        teamMembers: data.getAccountsForCompany.data
      })
    }

    Promise.all([fetchInvites(), fetchTeamMembers()])
    // eslint-disable-next-line
  }, [])

  return (
    <div className={styles.tableContainer}>
      <div className={styles.btnBar}>
        <h4>Team</h4>
        <Button
          className={styles.inviteBtn}
          onClick={event => history.push('/app/account/team/invite')}
        >
          Invite People
        </Button>
      </div>
      { teamMembersResponse.loading ? <div className='loader'>
        <Loader />
      </div> : <TeamList teamMembers={teamMembersResponse.teamMembers} /> }

      { invitesResponse.loading ? <div className='loader'>
        <Loader />
      </div> : <TeamListPending invites={invitesResponse.invites} /> }
    </div>
  )
}

export default Team
