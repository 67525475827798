import axios from 'axios'
import * as yup from 'yup'
import config from 'config'

export const sendMessage = async data => {
  const isValid = await validateData(data)

  if (isValid) {
    return axios
      .post(config.firebase.emailFunction, data)
      .then(({ data }) => data.status)
      .catch((error) => {
        throw new Error(error.message)
      })
  } else {
    throw new Error('Please provide valid data')
  }
}

const validateData = data => {
  const dataSchema = yup.object().shape({
    email: yup
      .string()
      .email()
      .required(),
    message: yup.string().required(),
    name: yup.string().required()
  })

  return dataSchema
    .isValid(data)
    .then(res => res)
    .catch(err => err)
}
