import { FormMessageTypes } from 'components/Forms/Input'
import reqClient from 'lib/reqClient'
import {
  GENERATE_ITEMS_PDF,
  UPDATE_ITEM,
  EMAIL_ITEMS
} from 'config/graphqlDefinitions'

const defaultLostStatus = 'Misplaced'

export const handleEmailItems = (items, setFormMessage) => {
  let queryResult = {
    query: EMAIL_ITEMS,
    variables: {
      selectedItems: items
    }
  }
  queryResult.variables.selectedItems =
    items && removeTypename(queryResult.variables.selectedItems)

  reqClient
    .query(queryResult)
    .then(({ data }) => {
      setFormMessage({
        message: data.emailItems || 'List of items has been sent to your email',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setFormMessage({
        message: err.message || 'Unknown error while generating pdf',
        type: FormMessageTypes.error
      })
    })
}

export const handleGenerateItemsPdf = (items, setFormMessage) => {
  let queryResult = {
    query: GENERATE_ITEMS_PDF,
    variables: {
      selectedItems: items
    }
  }

  queryResult.variables.selectedItems =
    items && removeTypename(queryResult.variables.selectedItems)

  reqClient
    .query(queryResult)
    .then(({ data }) => {
      setFormMessage({
        message: data.generateItemsPdfLink || 'PDF link emailed',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setFormMessage({
        message: err.message || 'Unknown error while generating pdf',
        type: FormMessageTypes.error
      })
    })
}

export const archiveItems = (items, setFormMessage, resetUI) => {
  const payload = constructArchiveItemPayload(items)
  let queryResult = {
    mutation: UPDATE_ITEM,
    variables: {
      input: payload
    }
  }

  queryResult.variables.selectedItems = removeTypename(
    queryResult.variables.input
  )

  reqClient
    .mutate(queryResult)
    .then(data => {
      let descriptorStr =
        data.data.updateItems.length === 1
          ? `${data.data.updateItems[0].name} has`
          : `${data.data.updateItems.length} Items have`
      resetUI()
      setFormMessage({
        message: `${descriptorStr} been archived`,
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setFormMessage({
        message: err.message || 'Unknown error while archiving items',
        type: FormMessageTypes.error
      })
    })
}

export const handleMarkItemsAsLost = (items, setFormMessage, resetItems) => {
  const payload = constructLostItemPayload(items)
  let queryResult = {
    mutation: UPDATE_ITEM,
    variables: {
      input: payload
    }
  }

  queryResult.variables.selectedItems = removeTypename(
    queryResult.variables.input
  )

  reqClient
    .mutate(queryResult)
    .then(res => {
      if (typeof resetItems === 'function') {
        resetItems(res.data.updateItems)
      }
      setFormMessage({
        message: 'Item(s) have been marked as lost',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setFormMessage({
        message: err.message || 'Unknown error while marking items as lost',
        type: FormMessageTypes.error
      })
    })
}

// gql adds __typename to normalize query data, however, it doesn't validate with the schema defined on api so we have to remove it
const removeTypename = payload => {
  payload.forEach(element => {
    element.__typename = undefined
    Object.keys(element).forEach(key => {
      if (element[key] instanceof Object) {
        element[key].__typename = undefined
      }
    })
  })
  return payload
}

const constructArchiveItemPayload = items => {
  const payload = items.map(({ id }) => ({
    id,
    isArchived: true
  }))
  return payload
}

const constructLostItemPayload = items => {
  const payload = items.map(({ id }) => ({
    id,
    lostStatus: defaultLostStatus
    // lostAt:
  }))
  return payload
}
