import firebase from 'services/firebase'
import PropTypes from 'prop-types'
import React, { useContext, useState } from 'react'

import { AuthContext } from 'context'
import { AccountInformation } from './AccountInformation'
import styles from './Account.module.scss'
import { FormGroup, Label, FormMessage, FormMessageTypes } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import { serializeForm } from 'lib/forms'
import PasswordField from 'components/Forms/PasswordField'

const ChangePassword = ({ userData }) => {
  const [loading, setLoading] = useState(false)
  const [message, setMessage] = useState(null)

  const handleChangePassword = async event => {
    event.preventDefault()

    setMessage(null)
    setLoading(true)

    const {
      confirmNewPassword,
      currentPassword,
      newPassword
    } = serializeForm(event)

    if (newPassword !== confirmNewPassword) {
      setLoading(false)
      setMessage({
        message: 'Your new password does not match',
        type: FormMessageTypes.error
      })
      return
    }

    // The user needs to login recently before they can change their password. This also validates the password
    const loginValid = await firebase.auth()
      .signInWithEmailAndPassword(userData.email, currentPassword)
      .then(() => true)
      .catch(() => false)

    if (!loginValid) {
      setLoading(false)
      setMessage({
        message: 'Your current password is not correct',
        type: FormMessageTypes.error
      })
      return
    }

    const user = firebase.auth().currentUser
    user.updatePassword(newPassword)
      .then((result) => {
        setMessage({
          message: 'Password successfully updated',
          type: FormMessageTypes.success
        })
      })
      .catch((err) => {
        setMessage({
          message: `Error updating password ${err && err.message ? `- ${err.message}` : ''}`,
          type: FormMessageTypes.error
        })
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <form onSubmit={handleChangePassword}>
      <FormGroup>
        <Label htmlFor='currentPassword'>Current Password</Label>
        <PasswordField
          autoComplete='password'
          id='currentPassword'
          name='currentPassword'
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='newPassword'>New Password</Label>
        <PasswordField
          autoComplete='new-password'
          id='newPassword'
          name='newPassword'
          required
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='confirmNewPassword'>Confirm New Password</Label>
        <PasswordField
          autoComplete='new-password'
          id='confirmNewPassword'
          name='confirmNewPassword'
          required
        />
      </FormGroup>

      {
        message && (
          <FormMessage
            message={message.message}
            type={message.type} />
        )
      }

      <Button
        loading={loading}
        type='submit'>
        Update Password
      </Button>
    </form>
  )
}

ChangePassword.propTypes = {
  userData: PropTypes.object
}

const Account = () => {
  //  Firebase User Object
  const userData = useContext(AuthContext)

  return (
    <div className={styles.account}>
      <h1 className={styles.accountTitle}>
        My Profile
      </h1>

      <div className={styles.accountCard}>
        <div className={styles.accountCardContent}>
          <h2 className={styles.accountCardTitle}>Account Details</h2>
          <AccountInformation userData={userData} />
        </div>
      </div>

      <div className={styles.accountCard}>
        <div className={styles.accountCardContent}>
          <h2 className={styles.accountCardTitle}>Change Password</h2>
          <ChangePassword userData={userData} />
        </div>
      </div>
    </div>
  )
}

export default Account
