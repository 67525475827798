import React from 'react'
import PropTypes from 'prop-types'
import { Route, Redirect } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { MdArrowBack } from 'react-icons/md'

import history from 'lib/history'
import { localStore } from 'lib/utils'
import config from 'config'
import styles from './AppRoute.module.scss'
import ForgotPassword from 'containers/Auth/ForgotPassword/ForgotPassword'
import ResetPassword from 'containers/Auth/ResetPassword/ResetPassword'
import Login from 'containers/Auth/Login/Login'
import Signup from 'containers/Auth/Signup/Signup'
import { Business, Personal } from 'containers/Auth/Signup/Forms'
import EmailActions from 'containers/Auth/EmailActions/EmailActions'
import Story from 'containers/Marketing/Story/Story'
import VerifyEmail from 'components/VerifyEmail/VerifyEmail'

const AuthRoute = ({
  component: RouteComponent,
  showBackArrow,
  title,
  ...rest
}) => {
  const user = localStore.get(config.storageKeys.userInfo)

  if (user && window.location.pathname !== '/auth/verify') {
    const redirectPath = window.location.pathname.includes('/app/') ? window.location.pathname : '/app/dashboard'

    return (
      <Redirect to={redirectPath} />
    )
  }

  return (
    <Route
      {...rest}
      render={props =>
        (
          <>
            {isMobile && title && (
              <div className={styles.titleBar}>
                {showBackArrow && (
                  <MdArrowBack
                    className={styles.backBtn}
                    onClick={() => history.goBack()}
                    size={24}
                  />
                )}
                <span>{title}</span>
              </div>
            )}
            <RouteComponent {...props} />
          </>
        )
      }
    />
  )
}

AuthRoute.defaultProps = {
  showBackArrow: true
}

AuthRoute.propTypes = {
  component: PropTypes.func,
  showBackArrow: PropTypes.bool,
  title: PropTypes.string
}

const AuthRoutes = () => {
  return (
    <>
      <Route
        component={EmailActions}
        exact
        path='/auth/email-actions' />
      <Route
        component={VerifyEmail}
        exact
        path='/auth/verification-required' />
      <Route
        component={Story}
        exact
        path='/about' />
      <AuthRoute
        component={Login}
        exact
        path='/auth/login'
        title='Login'
      />
      <Route
        component={ForgotPassword}
        path='/auth/forgot-password'
      />
      <Route
        component={ResetPassword}
        path='/auth/reset-password' />
      <AuthRoute
        component={Signup}
        exact
        path='/auth/signup'
        title='Signup'
      />
      <AuthRoute
        component={Business}
        exact
        path='/auth/signup/business'
        title='Create Business Account'
      />
      <AuthRoute
        component={Personal}
        exact
        path='/auth/signup/personal'
        title='Personal Sign Up'
      />
      <AuthRoute
        component={Personal}
        exact
        path='/auth/signup/personal/:inviteCode'
        title='Invitation Accepted'
      />
    </>
  )
}

export default AuthRoutes
