import React from 'react'
import PropTypes from 'prop-types'
import { composeClasses } from 'lib/utils'
import { IconContext } from 'react-icons'
import { FaBars } from 'react-icons/fa'

import styles from './BurgerMenu.module.scss'
import NavItems from 'components/Nav/NavItems'

class BurgerMenu extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      navMenuOpen: false
    }
    this.toggleMenu = this.toggleMenu.bind(this)
  }

  toggleMenu (e) {
    e.preventDefault()
    this.setState(prevState => {
      return {
        navMenuOpen: !prevState.navMenuOpen
      }
    })
  }

  backdropClickHandler () {
    this.setState({
      navMenuOpen: false
    })
  }

  render () {
    const toggleMenuClass = this.props.isLoggedIn ? styles.displayLoggedIn : styles.displayLoggedOut
    return (
      <>
        <IconContext.Provider value={{ size: '1.5em' }}>
          <span
            className={composeClasses(styles.toggleMenu, toggleMenuClass)}
            onClick={this.toggleMenu}
          >
            {' '}
            <FaBars />
          </span>
        </IconContext.Provider>
        <NavItems
          show={this.state.navMenuOpen}
          toggleMenu={event => this.toggleMenu(event)}
        />
      </>
    )
  }
}

BurgerMenu.propTypes = {
  isLoggedIn: PropTypes.bool
}

export default BurgerMenu
