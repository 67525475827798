import React, { useState, useEffect } from 'react'
import { useApolloClient } from 'react-apollo'

import ItemsList from 'components/ItemsList/ItemsList'
import styles from './ArchivedItem.module.scss'
import { GET_ITEMS } from 'config/graphqlDefinitions'

import Loader from 'components/Loader/Loader'

const ArchivedItem = () => {
  const [itemsResponse, setItemsResponse] = useState({
    error: '',
    items: [],
    loading: true
  })

  const client = useApolloClient()

  const getItemsQuery = async () => {
    const { data, loading, error } = await client
      .query({
        fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
        query: GET_ITEMS,
        variables: {
          filters: { isArchived: true }
        }
      })

    setItemsResponse({
      error,
      items: (data && data.getItems && data.getItems.data) || [],
      loading
    })
  }

  useEffect(() => {
    getItemsQuery()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showItems = itemsResponse.items.length > 0

  return (
    <div className={styles.columnRight}>
      <div className={styles.itemsView}>
        <h4 style={{ display: showItems ? 'block' : 'none' }}>
          Archived Items
        </h4>
        <ItemsList
          displaySummary={false}
          getItemsQuery={getItemsQuery}
          itemsResponse={itemsResponse}
          renderCheckboxes={false}
          style={{ display: showItems ? 'block' : 'none' }}
        />

        <p style={{ display: !showItems && !itemsResponse.loading ? 'block' : 'none' }}> No items archived </p>
      </div>

      {itemsResponse.loading && <div className='loader'>
        <Loader />
      </div>}
    </div>
  )
}

export default ArchivedItem
