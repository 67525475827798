/* eslint-disable jsx-a11y/alt-text */
import React, { useContext, useState, useEffect } from 'react'
import { isMobile } from 'react-device-detect'
import { ModalContext } from 'context'
import history from 'lib/history'
import { FaCircle } from 'react-icons/fa'
import AddItem from 'components/AddItem'
import Button from 'components/Button/Button'
import { FormatCurrency } from 'components/Formatter/Formatter'
import styles from './ItemsSummary.module.scss'
import PropTypes from 'prop-types'

import item from '../../assets/images/icons/item.svg'
import insured from '../../assets/images/icons/insured.svg'

const ItemsSummary = ({ count, getItemsQuery, getItemsSummary }) => {
  const { setModalState } = useContext(ModalContext)
  const [items, setItems] = useState([])
  const showAddItemModal = () => {
    setModalState({
      component: props => <AddItem
        getItemsQuery={getItemsQuery}
        {...props} />,
      visible: true
    })
  }
  const renderAddItem = () => {
    isMobile ? history.push('/app/item/add') : showAddItemModal()
  }
  const totalInsuredValue = items => {
    let value = 0

    items.map(item => {
      value += parseInt(item.insuredValue)
      return value
    })

    return (
      <div className={styles.value}>
        <FormatCurrency value={value.toString()} />
        <span>Value of items</span>
      </div>
    )
  }

  const renderItemsCount = items => {
    const insuredItems = items.filter(item => item.insurer)

    return (
      <div className={styles.counterBox}>
        <div className={styles.counter}>
          <div className={styles.image}>
            <FaCircle
              className={styles.circle}
              size='48px' />
            <img
              className={styles.icon}
              src={item} />
          </div>
          {count} Items
        </div>
        <div className={styles.counter}>
          <div className={styles.image}>
            <FaCircle
              className={styles.circle}
              size='48px' />
            <img
              className={styles.icon}
              src={insured} />
          </div>
          {insuredItems.length} Insured
        </div>
      </div>
    )
  }
  const renderAddItemButton = () => {
    return (
      <div className={styles.addItemBox}>
        <Button
          className={styles.itemPrimary}
          onClick={renderAddItem}>Add Item</Button>
      </div>
    )
  }

  useEffect(() => {
    const getItems = async () => {
      const data = await getItemsSummary()
      setItems(data)
    }

    getItems()
  }, [getItemsSummary])

  return (
    <div className={styles.container}>
      {totalInsuredValue(items)}
      {renderItemsCount(items)}
      {renderAddItemButton()}
    </div>
  )
}

ItemsSummary.propTypes = {
  count: PropTypes.number.isRequired,
  getItemsQuery: PropTypes.func.isRequired,
  getItemsSummary: PropTypes.func.isRequired
}

export default ItemsSummary
