import React, { useState, useContext } from 'react'

import { handleResendVerification } from './verifyEmail.service'
import authStyles from '../../containers/Auth/auth.module.scss'
import { AuthCTA } from '../../containers/Auth/auth.shared'
import { MdArrowBack } from 'react-icons/md'
import Button from 'components/Button/Button'
import { FormMessage } from 'components/Forms/Input'
import history from 'lib/history'

import { AuthContext } from 'context'

export const VerifyEmail = () => {
  const userData = useContext(AuthContext)

  const [dispatch, setDispatch] = useState({ loading: false, message: '', type: '' })

  return (
    <div className={authStyles.fullscreenWrapper}>
      <div className={authStyles.container}>
        <div className={authStyles.verifyHeading}>
          <MdArrowBack
            className={authStyles.backBtn}
            onClick={event => history.goBack()}
          />
          <span>Confirm Your Email Address</span>
        </div>
        <FormMessage
          message={dispatch.message}
          type={dispatch.type}
        />
        <AuthCTA
          ctaButton={() => (
            <Button
              loading={dispatch.loading}
              onClick={event => handleResendVerification(event, setDispatch, userData)}>
              Resend Verification Email
            </Button>
          )}
          title='We need to confirm your email address. Please click the verification link in your email.'
        />
      </div>
    </div>
  )
}

export default VerifyEmail
