import React from 'react'
import PropTypes from 'prop-types'
import { Switch, Route } from 'react-router-dom'
import { isMobile } from 'react-device-detect'
import { MdArrowBack } from 'react-icons/md'

import history from 'lib/history'
import styles from './AppRoute.module.scss'
import Team from 'containers/Account/Team/Team'
import TeamInvite from 'containers/Account/Team/Invite'
import Account from 'containers/Account/Account'
import Dashboard from 'containers/App/Dashboard/Dashboard'
import AddItem from 'containers/App/AddItem'
import EditItem from 'containers/App/EditItem'
import ArchivedItem from 'containers/App/ArchivedItem'
import NavItems from 'components/Nav/NavItems'
import { composeClasses } from 'lib/utils'

const AppRoute = ({
  component: RouteComponent,
  showBackArrow,
  title,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props =>
        (
          <>
            {isMobile && title && (
              <div className={styles.titleBar}>
                {showBackArrow && (
                  <MdArrowBack
                    className={styles.backBtn}
                    onClick={() => history.goBack()}
                    size={24}
                  />
                )}
                <span>{title}</span>
              </div>
            )}

            <div className={composeClasses(styles.container, styles.main)}>
              <div className={styles.columnLeft}>
                <NavItems
                  customStyles={styles}
                  show />
              </div>

              <main className={styles.columnRight}>
                <RouteComponent {...props} />
              </main>
            </div>

          </>
        )
      }
    />
  )
}

AppRoute.defaultProps = {
  showBackArrow: true
}

AppRoute.propTypes = {
  component: PropTypes.func,
  showBackArrow: PropTypes.bool,
  title: PropTypes.string
}

const AppRoutes = () => {
  return (
    <Switch>
      <AppRoute
        component={Dashboard}
        exact
        path='/app/dashboard'
        showBackArrow={false}
        title='Dashboard'
      />
      <AppRoute
        component={AddItem}
        exact
        path='/app/item/add'
        title='Add Item'
      />
      <AppRoute
        component={EditItem}
        exact
        path='/app/item/edit'
        title='Edit Item'
      />
      <AppRoute
        component={ArchivedItem}
        exact
        path='/app/item/archive'
        title='Archived Items'
      />
      <AppRoute
        component={Account}
        exact
        path='/app/account'
        title='Account'
      />
      <AppRoute
        component={Team}
        exact
        path='/app/account/team'
        title='Team'
      />
      <AppRoute
        component={TeamInvite}
        exact
        path='/app/account/team/invite'
        title='Invite Member'
      />
    </Switch>
  )
}

export default AppRoutes
