export default {
  api: {
    errorMessages: {
      unverified: 'You need to verify your email address first.'
    },
    root: process.env.API_URL || 'https://suresure.appspot.com'
  },
  app: {
    tempInsurersEmail: 'daneel+insurer@deimos.co.za'
  },
  firebase: {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
    authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
    databaseURL: process.env.REACT_APP_FIREBASE_DATABASE_URL,
    emailFunction: process.env.REACT_APP_FIREBASE_FUNCTION_URL,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET
  },
  storageKeys: {
    authToken: 'auth_token',
    userInfo: 'user_info'
  }
}
