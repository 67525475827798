import React from 'react'
import PropTypes from 'prop-types'
import { Check, MoreVert } from '@material-ui/icons'
import { composeClasses } from 'lib/utils'

import Button from 'components/Button/Button'
import ItemStatus from 'components/ItemStatus/ItemStatus'
import ItemActions from 'components/ItemActions/ItemActions'
import { FormatCurrency, FormatDate } from 'components/Formatter/Formatter'
import styles from './Item.module.scss'

class Item extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      item: {},
      showActions: false
    }
    this.props = props
    this.itemSelected = this.itemSelected.bind(this)
    this.showActions = this.showActions.bind(this)
    this.resetItem = this.resetItem.bind(this)
  }

  resetItem (newItem) {
    // select the first item in the array as this applies to individual item
    this.setState({
      item: { ...newItem[0], itemSelected: false }
    })
  }

  componentDidMount () {
    this.setState({
      item: { ...this.props.item, itemSelected: false }
    })
  }

  itemSelected (e) {
    e.preventDefault()
    e.stopPropagation()

    this.setState(
      {
        item: {
          ...this.state.item,
          itemSelected: !this.state.item.itemSelected
        }
      },
      () => {
        this.props.handleSelectItem(this.state.item)
      }
    )
  }

  showActions (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      showActions: !this.state.showActions
    })

    if (!this.state.item.itemSelected || this.state.showActions) {
      this.itemSelected(e)
    }
  }

  renderItemImage () {
    const item = this.state.item
    return (
      <div className={styles.image}>
        <span>
          {item.photoUrls && item.photoUrls[0] && (
            <img
              alt={item.name}
              src={item.photoUrls[0]}
              style={{ width: '100%' }}
            />
          )}
        </span>
      </div>
    )
  }

  renderItemDetails () {
    const item = this.state.item
    return (
      <div className={styles.detailsContainer}>
        <div className={styles.details}>
          <div className={styles.detailColumn}>
            <div className={styles.name}>{item.name}</div>
            <div className={styles.extra}>
              {item.createdOn && (
                <span>
                  {`Added: `}
                  <FormatDate
                    className={styles.date}
                    date={item.createdOn} />
                </span>
              )}
            </div>
          </div>
          <div className={styles.price}>
            {item.insuredValue && (
              <FormatCurrency
                className={styles.value}
                value={item.insuredValue}
              />
            )}
          </div>
        </div>
        <ItemStatus
          statuses={[
            {
              title: 'Photo',
              type:
                item.photoUrls && item.photoUrls.length ? 'success' : 'error'
            },
            {
              title: 'Receipt',
              type: item.receiptUrl ? 'success' : 'error'
            },
            {
              title: 'Insured',
              type: item.insurer ? 'success' : 'error'
            },
            {
              title: 'Lost Item',
              type: item.lostStatus ? 'lost' : null
            }
          ]}
        />
      </div>
    )
  }

  componentWillMount () {
    document.addEventListener('click', this.handleClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClick, false)
  }

  handleClick = event => {
    if (!this.node.contains(event.target)) {
      this.handleClickOutside()
    }
  }

  handleClickOutside = () => {
    if (this.state.showActions) {
      this.setState({
        showActions: false
      })
    }
  }

  render () {
    const isArchivePage = () => {
      return window.location.pathname === '/app/item/archive'
    }
    const item = this.state.item
    const itemBackgroundClass = this.props.alternateColor ? styles.backgroundGrey : styles.backgroundWhite
    const itemPaddingClass = isArchivePage() ? styles.paddingAdd : ''
    const menuDisplayClass = !isArchivePage() ? styles.menuShow : styles.menuHide

    return (
      <div
        className={composeClasses(
          styles.item,
          styles[item.itemSelected ? 'active' : ''],
          itemBackgroundClass,
          itemPaddingClass
        )}
      >
        {this.props.renderCheckbox && (
          <div className={styles.select}>
            <Button
              className={styles.button}
              color='secondary'
              disabled={this.state.showActions}
              onClick={e => this.itemSelected(e)}
            >
              {item.itemSelected && <Check fontSize='inherit' />}
            </Button>
          </div>
        )}
        {this.renderItemImage()}
        {this.renderItemDetails(item)}
        <div
          className={composeClasses(styles.more, menuDisplayClass)}
          ref={node => {
            this.node = node
          }}
        >
          <Button
            className={styles.button}
            color='secondary'
            onClick={e => this.showActions(e)}
          >
            <MoreVert />
          </Button>
          {this.state.showActions && (
            <ItemActions
              className={styles.actionList}
              closeActions={e => this.showActions(e)}
              handlers={this.props.handlers}
              resetItems={this.resetItem}
            />
          )}
        </div>
      </div>
    )
  }
}

Item.propTypes = {
  alternateColor: PropTypes.bool,
  handleSelectItem: PropTypes.func,
  handlers: PropTypes.object,
  item: PropTypes.object,
  renderCheckbox: PropTypes.bool
}

export default Item
