import React from 'react'
import * as yup from 'yup'
import PropTypes from 'prop-types'
import { MdClear } from 'react-icons/md'
import { Mutation, Query } from 'react-apollo'
import omit from 'lodash.omit'

import { FormMessageTypes } from 'components/Forms/Input'
import styles from './AddItem.module.scss'
import { ADD_ITEM, GET_INSURERS } from 'config/graphqlDefinitions'
import ItemForm from 'components/ItemForm/ItemForm'

const AddItemForm = ({ closeFn, getItemsQuery }) => {
  return (
    <Mutation mutation={ADD_ITEM}>
      {
        (addItem, { loading, error }) => {
          const handleSubmission = async (payload) => {
            return new Promise(async (resolve, reject) => {
              const schema = yup.object().shape({
                insurer: yup.string(),
                name: yup.string().required('Name is a required field'),
                serialNumberText: yup.string()
              })

              const errMsg = await schema.validate(payload)
                .then(() => '')
                .catch((err) => err.message)

              if (errMsg) {
                reject(new Error({
                  body: {
                    message: errMsg,
                    type: FormMessageTypes.error
                  }
                }))
                return
              }

              addItem({
                variables: omit(payload, ['deletedPhotos'])
              })
                .then(response => {
                  // Reload the items on Desktop. Reloading isn't necessary on mobile cause visiting the dashboard reloads items list
                  getItemsQuery && getItemsQuery()

                  const productName = response.data.addItem.name

                  resolve({
                    body: {
                      message: `${productName} has been added.`,
                      subtitle: 'Add another item or go back to your dashboard to view your list.',
                      type: FormMessageTypes.success
                    }
                  })
                })
                .catch(error => {
                  reject(new Error({
                    message: error.message,
                    type: FormMessageTypes.error
                  }))
                })
            })
          }

          return (
            <ItemForm
              closeFn={closeFn}
              error={error}
              handleFormSubmission={handleSubmission}
              loading={loading}
              resetAfterSubmission
            />
          )
        }
      }
    </Mutation >
  )
}

AddItemForm.propTypes = {
  closeFn: PropTypes.func,
  getItemsQuery: PropTypes.func.isRequired
}

const AddItem = ({ closeFn, getItemsQuery }) => {
  return (
    <Query query={GET_INSURERS}>
      {
        ({ data }) => {
          return (
            <div className={styles.addItem}>
              <header className={styles.addItemHeader}>
                Add an item
                <MdClear
                  color='#acacac'
                  onClick={closeFn}
                  size={24}
                />
              </header>
              <div className={styles.addItemContent}>
                <AddItemForm
                  closeFn={closeFn}
                  getItemsQuery={getItemsQuery}
                />
              </div>
            </div>
          )
        }
      }
    </Query>
  )
}

AddItem.propTypes = {
  closeFn: PropTypes.func,
  getItemsQuery: PropTypes.func.isRequired
}

export default AddItem
