import React from 'react'
import PropTypes from 'prop-types'

import styles from './Button.module.scss'
import { composeClasses } from 'lib/utils'
import Loader from 'components/Loader/Loader'
import GenericTransition from 'components/GenericTransition/GenericTransition'

const Button = ({
  children,
  className,
  color,
  loading,
  disabled,
  ...props
}) => {
  return (
    <button
      className={composeClasses(styles.button, styles[color], className)}
      disabled={loading || disabled}
      {...props}
    >
      <>
        {loading ? (
          <GenericTransition show={loading}>
            <Loader />
          </GenericTransition>
        ) : (
          children
        )}
      </>
    </button>
  )
}

Button.defaultProps = {
  color: 'primary'
}

Button.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.string,
  disabled: PropTypes.bool,
  loading: PropTypes.bool
}

export default Button
