import React, { useState, useContext } from 'react'
import PropTypes from 'prop-types'
import { FaCheck } from 'react-icons/fa'
import { MdAdd } from 'react-icons/md'
import { useQuery } from 'react-apollo'

import Button from 'components/Button/Button'
import {
  FormGroup,
  Label,
  Input,
  SelectInput,
  CurrencyInput,
  FormMessage,
  FormMessageTypes
} from 'components/Forms/Input'
import { serializeForm } from '../../lib/forms'
import { isNotEmptyArray } from 'lib/utils'
import { AuthContext } from 'context'
import { GET_INSURERS } from 'config/graphqlDefinitions'
import styles from './ItemForm.module.scss'
import FileInput from 'components/Forms/FileInput'
import history from 'lib/history'

const FORM_DISMISS_INTERVAL = 2000

const ItemForm = ({
  closeFn,
  defaultValues, // Default values (for the form)
  error,
  handleFormSubmission,
  handlers,
  loading,
  resetAfterSubmission
}) => {
  const [formMessage, setFormMessage] = useState({
    message: '',
    subtitle: '',
    type: ''
  })
  const [photos, setPhotos] = useState()
  // Deleted photos
  const [deletedPhotos, setDeletedPhotos] = useState([])
  const [receipt, setReceipt] = useState()
  // Reset the form values (primarily for the input fields)
  const [resetForm, setResetForm] = useState()

  const authContext = useContext(AuthContext)
  const userInfo = authContext && authContext.userInfo

  const formValues = defaultValues || {
    assignee: '',
    insuredValue: '',
    insurer: '',
    name: '',
    photoUrls: [],
    receiptUrl: '',
    serialNumberText: []
  }

  const { data: insurersData } = useQuery(GET_INSURERS)
  const insurers = insurersData && insurersData.getInsurers && insurersData.getInsurers.data

  return (
    <form
      onSubmit={event => {
        event.preventDefault()
        event.persist()
        const serializedFormData = serializeForm(event)

        const payload = {
          ...serializedFormData,
          deletedPhotos,
          insuredValue: parseInt(serializedFormData.insuredValue || 0),
          photos,
          receipt
        }

        handleFormSubmission(payload)
          .then((response) => {
            const message = response && response.body
            if (message) {
              setFormMessage(message)
            }

            if (resetAfterSubmission) {
              event.target.reset()
              setPhotos()
              setReceipt()
              setResetForm(true)
            }

            setDeletedPhotos([])
            setTimeout(closeFn, FORM_DISMISS_INTERVAL)
          })
          .catch((err) => {
            if (err && err.body) {
              // Render errors
              setFormMessage(err.body)
              return
            }

            console.warn('Unhandled error while submitting form', err)
          })
      }}>

      <div
        className={styles.messageContent}
        style={{ display: formMessage.message ? 'flex' : 'none' }}
      >
        <span>
          <FaCheck />
        </span>
        <div>
          <div className={styles.messageContentHeading}>
            <FormMessage
              message={formMessage.message || (error && error.toString())}
              type={error ? FormMessageTypes.error : formMessage.type} />
          </div>
          {formMessage.subtitle && (
            <p className={styles.messageContentSubHeading}>{formMessage.subtitle}</p>
          )}
        </div>
      </div>

      <FormGroup>
        <Label htmlFor='name'>Give the item a name</Label>
        <Input
          defaultValue={formValues.name}
          id='name'
          name='name'
          placeholder='Eg. Samsung S9 Phone '
          required
          type='text'
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='insuredValue'>What is the value of your item?</Label>
        <CurrencyInput
          defaultValue={formValues.insuredValue}
          id='insuredValue'
          name='insuredValue'
          placeholder='Eg. 15243.15'
          type='number'
        />
      </FormGroup>

      <FormGroup>
        <Label htmlFor='insurer'>Who is it insured with?</Label>
        <SelectInput
          defaultValue={formValues.insurer && formValues.insurer.id}
          label='insurer'>
          <option value=''>Select your Insurer</option>
          {
            isNotEmptyArray(insurers) && insurers.map(insurer => (
              <option
                key={insurer.id}
                value={insurer.id}>{insurer.name}</option>
            ))
          }
        </SelectInput>
      </FormGroup>

      {
        userInfo && userInfo.company && (
          <FormGroup>
            <Label htmlFor='assignee'>
              Who is this item assigned to? <small>(optional)</small>
            </Label>
            <Input
              defaultValue={formValues.assignee}
              id='assignee'
              name='assignee'
              placeholder='Enter an assignee'
              type='text'
            />
          </FormGroup>
        )
      }
      <FormGroup>
        <Label htmlFor='photos'>Photos of your item</Label>
        <FileInput
          accept='image/*'
          defaultValues={formValues.photoUrls || []}
          handleDeletedFile={handlers && handlers.photos}
          multiple
          name='photos'
          resetFiles={resetForm}
          updater={(files, deletedFiles) => {
            setPhotos(files)

            if (deletedFiles) {
              setDeletedPhotos(deletedFiles)
            }
          }}
        >
          <MdAdd size={18} />
          Add a photo of your item
        </FileInput>
      </FormGroup>

      <FormGroup>
        <Label htmlFor='receipt'>Copy of your receipt</Label>
        <FileInput
          accept='image/*,application/pdf'
          defaultValues={formValues.receiptUrl ? [formValues.receiptUrl] : []}
          handleDeletedFile={handlers && handlers.receipt}
          multiple={false}
          name='receipt'
          resetFiles={resetForm}
          updater={(files) => {
            setReceipt(files[0])
          }}
        >
          <MdAdd size={20} />
          Add copy of your receipt
        </FileInput>
      </FormGroup>

      <FormGroup>
        <Label htmlFor='serialNumberText'>
          Item's serial number <small>(optional)</small>
        </Label>
        <Input
          defaultValue={formValues.serialNumberText}
          id='serialNumberText'
          name='serialNumberText'
          type='text'
        />
      </FormGroup>

      {/* NOTE: This is currently not needed for this sprint */}
      {/* <FormGroup>
              <Label htmlFor='lostStatus'>Lost Status</Label>
              <SelectInput name='lostStatus'>
                <option value=''>Select a status</option>
                <option value='Misplaced'>Misplaced</option>
                <option value='Stolen'>Stolen</option>
              </SelectInput>
            </FormGroup> */}

      <div className={styles.formActions}>
        <Button
          className={styles.cancelButton}
          onClick={closeFn !== undefined ? closeFn : () => { history.push('/app/dashboard') }}
          type='button'
        >
          Cancel
        </Button>

        <Button
          className={styles.submitButton}
          loading={loading}
          type='submit'>
          Save Item
        </Button>
      </div>
    </form>
  )
}

ItemForm.propTypes = {
  closeFn: PropTypes.func.isRequired,
  defaultValues: PropTypes.object,
  error: PropTypes.object,
  handleFormSubmission: PropTypes.func.isRequired,
  handlers: PropTypes.object,
  loading: PropTypes.bool.isRequired,
  resetAfterSubmission: PropTypes.bool
}

export default ItemForm
