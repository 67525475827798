import React from 'react'
import PropTypes from 'prop-types'

import styles from './auth.module.scss'

export const AuthCTA = ({ ctaButton: CTA, title, text }) => {
  return (
    <div className={styles.cta}>
      {title && <h3 className={styles.ctaTitle}>{title}</h3>}
      {text && <h3 className={styles.ctaText}>{text}</h3>}

      <CTA />
    </div>
  )
}

AuthCTA.propTypes = {
  ctaButton: PropTypes.func.isRequired,
  text: PropTypes.string,
  title: PropTypes.string
}
