import React from 'react'
import PropTypes from 'prop-types'

import styles from './Input.module.scss'
import { composeClasses } from 'lib/utils'
import GenericTransition from 'components/GenericTransition/GenericTransition'

export const FormGroup = ({ children, className }) => {
  return (
    <div className={composeClasses(styles.formGroup, className)}>
      {children}
    </div>
  )
}

FormGroup.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export const Label = ({ children, className, ...props }) => {
  return (
    <label
      className={composeClasses(className, styles.label)}
      {...props}>
      {children}
    </label>
  )
}

Label.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export const Input = ({ className, ...props }) => {
  return (
    <input
      className={composeClasses(className, styles.input)}
      {...props} />
  )
}

Input.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export const FormMessageTypes = {
  error: 'error',
  success: 'success'
}

export const FormMessage = ({ message, type }) => {
  if (!message || !type) {
    return null
  }

  const showMessage = !(!message || !type)

  return (
    <GenericTransition show={showMessage}>
      <div
        className={composeClasses(styles.message, styles[`message-${type}`])}
      >
        {message}
      </div>
    </GenericTransition>
  )
}

FormMessage.defaultProps = {
  message: ''
}

FormMessage.propTypes = {
  message: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export const SelectInput = ({ className, defaultValue, label, children }) => {
  return (
    <select
      className={composeClasses(styles.selectContainer, className || '')}
      defaultValue={defaultValue}
      id={label}
      name={label}
    >
      {children}
    </select>
  )
}

SelectInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
  label: PropTypes.string
}

export const CurrencyInput = ({ className, ...props }) => {
  return (
    <div className={styles.currencyContainer}>
      <span className={styles.currencyLogo}>R</span>
      <input
        className={composeClasses(className, styles.currencyInput)}
        min={0}
        {...props}
      />
    </div>
  )
}

CurrencyInput.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  label: PropTypes.string
}
