import React, { useContext, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { isMobile } from 'react-device-detect'
import { useApolloClient } from 'react-apollo'

import history from 'lib/history'
import { ModalContext } from 'context'
import Button from 'components/Button/Button'
import Pagination from 'components/Pagination/Pagination'
import { GET_ITEMS } from 'config/graphqlDefinitions'
import AddItem from 'components/AddItem'
import { composeClasses, arrayToObject, objectToArray } from 'lib/utils'
import ItemsList from 'components/ItemsList/ItemsList'
import { FaBox } from 'react-icons/fa'

import styles from './Dashboard.module.scss'
import authStyles from '../../Auth/auth.module.scss'
import config from 'config'
import Loader from 'components/Loader/Loader'

const dashboardFilters = ['insured', 'lostStatus']

const WelcomeBlock = ({ getItemsQuery }) => {
  const { setModalState } = useContext(ModalContext)

  const showAddItemModal = () => {
    setModalState({
      component: props => <AddItem
        getItemsQuery={getItemsQuery}
        {...props} />,
      visible: true
    })
  }

  const renderAddItem = () => {
    isMobile ? history.push('/app/item/add') : showAddItemModal()
  }

  return (
    <>
      <FaBox className={styles.icon} />
      <span className={styles.copyContainer}>
        <h1>
          Welcome <span className={styles.noWrap}>to SureSure</span>
        </h1>
        <p>Add your first item to get started</p>
      </span>
      <Button onClick={renderAddItem}>Add Item</Button>
    </>
  )
}

WelcomeBlock.propTypes = {
  getItemsQuery: PropTypes.func.isRequired
}

const Dashboard = () => {
  // Item response
  const [itemsResponse, setItemsResponse] = useState({
    count: '',
    error: '',
    items: [],
    loading: true,
    pagination: {
      currentPageCursor: null,
      nextPageCursor: null
    }
  })
  const [prevPageCursors, setPrevPageCursors] = useState([])
  // If the verification modal should be shown
  const [showVerification, setShowVerification] = useState(false)
  const [filterValues, setFilterValues] = useState({
    filter: '',
    message: ''
  })
  const client = useApolloClient()

  const handlePageChange = async cursor => {
    if (cursor === itemsResponse.pagination.currentPageCursor) {
      return
    }
    if (!prevPageCursors.includes(itemsResponse.pagination.currentPageCursor)) {
      setPrevPageCursors([...prevPageCursors, itemsResponse.pagination.currentPageCursor])
    }

    await getItemsQuery({
      pageSize: 10,
      startCursor: cursor
    })
  }

  const getItemsQuery = async (variables, useFilter = true) => {
    let defaultFilters = { isArchived: false }
    let isFilteredQuery

    if (prevPageCursors.includes(variables.startCursor)) {
      prevPageCursors.pop()
      setPrevPageCursors(prevPageCursors)
    }
    if (variables && variables.filters) {
      defaultFilters = { ...defaultFilters, ...variables.filters }
      const filter = Object.keys(variables.filters)[0]
      isFilteredQuery = dashboardFilters.includes(filter)

      if (isFilteredQuery) {
        setFilterValues({
          filter,
          message: filter === 'insured' ? 'No insured items' : 'No lost items'
        })
      }
    }

    // eslint-disable-next-line sort-keys
    const queryVariables = { pageSize: 10, ...variables, filters: defaultFilters }
    const { data, loading, error } = await client
      .query({
        fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
        query: GET_ITEMS,
        variables: useFilter ? queryVariables : {}
      })
      .catch(err => {
        const networkError = err && err.networkError

        const authError =
          networkError &&
          networkError.result &&
          networkError.result.errors[0].message

        const apiErrorMessages = config.api.errorMessages

        if (
          networkError &&
          authError &&
          authError.includes(apiErrorMessages.unverified)
        ) {
          setShowVerification(true)
        }

        return {}
      })

    !isFilteredQuery &&
      setFilterValues({
        // reset filter values
        filter: '',
        message: ''
      })

    setItemsResponse({
      count: data.getItems.count,
      error,
      items: (data && data.getItems && data.getItems.data) || [],
      loading,
      pagination: data.getItems.pagination
    })
  }

  const getItemsSummary = async () => {
    const { data } = await client
      .query({
        fetchPolicy: 'no-cache', // Force the query to be re-run each time to get the latest items
        query: GET_ITEMS
      })
      .catch(err => {
        const networkError = err && err.networkError

        const authError =
          networkError &&
          networkError.result &&
          networkError.result.errors[0].message

        const apiErrorMessages = config.api.errorMessages

        if (
          networkError &&
          authError &&
          authError.includes(apiErrorMessages.unverified)
        ) {
          setShowVerification(true)
        }

        return {}
      })

    return data.getItems.data
  }

  useEffect(() => {
    getItemsQuery({
      filters: { isArchived: false }
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const resetItems = items => {
    const newData = Object.assign(
      {},
      arrayToObject(itemsResponse.items),
      arrayToObject(items)
    )
    setItemsResponse({ ...itemsResponse, items: objectToArray(newData) })
  }
  const cursor = itemsResponse.pagination
  const showItems = itemsResponse.items.length > 0

  return (
    <div>
      {!showVerification &&
        !itemsResponse.loading &&
        !showItems &&
        !filterValues.filter && (
        <div
          className={composeClasses(
            authStyles.container,
            styles.welcomeBlock
          )}
        >
          <WelcomeBlock getItemsQuery={getItemsQuery} />
        </div>
      )}

      {!showVerification && (
        <div className={styles.itemsView}>
          <h4
            style={{
              display: showItems || filterValues.filter ? 'block' : 'none'
            }}
          >
            Dashboard
          </h4>
          <ItemsList
            displaySummary
            filterValues={filterValues}
            getItemsQuery={getItemsQuery}
            getItemsSummary={getItemsSummary}
            itemsResponse={itemsResponse}
            resetItems={resetItems}
            style={{
              display: showItems || filterValues.filter ? 'block' : 'none'
            }}
          />
          <Pagination
            currentPageCursor={cursor.currentPageCursor}
            handlePageChange={handlePageChange}
            nextPageCursor={cursor.nextPageCursor}
            prevPageCursor={prevPageCursors[prevPageCursors.length - 1]}
          />
        </div>
      )}

      {itemsResponse.loading && (
        <div className='loader'>
          <Loader />
        </div>
      )}
    </div>
  )
}

export default Dashboard
