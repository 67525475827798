import React, { useContext, useState } from 'react'
import history from 'lib/history'

import ItemsSummary from 'components/ItemsSummary/ItemsSummary'
import { FormMessage, FormMessageTypes } from 'components/Forms/Input'
import ToolBar from 'components/Toolbar/Toolbar'
import List from 'components/List/List'
import Item from 'components/Item/Item'
import styles from './ItemsList.module.scss'
import PropTypes from 'prop-types'
import {
  handleGenerateItemsPdf,
  archiveItems,
  handleEmailItems,
  handleMarkItemsAsLost
} from 'components/ItemsList/items.service'
import { isMobile } from 'react-device-detect'
import EditItem from 'components/EditItem'
import ConfirmationBox from 'components/ConfirmationBox'
import { ModalContext } from 'context'
import Loader from 'components/Loader/Loader'

const MESSAGE_DISPLAY_INTERVAL = 5000

const ItemsList = ({
  renderCheckboxes,
  getItemsSummary,
  getItemsQuery,
  itemsResponse,
  itemsChanged = null,
  displaySummary,
  resetItems,
  filterValues,
  count
}) => {
  const [selectedItems, setSelectedItems] = useState([])
  const [formMessage, setFormMessage] = useState({
    message: '',
    type: ''
  })
  const { setModalState } = useContext(ModalContext)
  const { error, items, loading } = itemsResponse
  const [sortLoader, setSortLoader] = useState(false)
  const [filterLoader, setFilterLoader] = useState(false)
  var [alternateColor] = useState(false)

  const setMessage = messageObject => {
    setFormMessage(messageObject)
    setTimeout(() => {
      setFormMessage({
        message: '',
        type: ''
      })
    }, MESSAGE_DISPLAY_INTERVAL)
  }

  const getAlternateColor = () => {
    alternateColor = !alternateColor
    return alternateColor
  }

  const handleDownloadPDF = () => {
    const itemsToPrint = selectedItems.length ? selectedItems : items
    setModalState({
      component: props => (
        <ConfirmationBox
          actionFn={() => handleGenerateItemsPdf(itemsToPrint, setMessage)}
          content={`${itemsToPrint.length} item${
            itemsToPrint.length > 1 ? 's' : ''
          } selected, A link to generate the pdf will be generated and sent to your email`}
          title='Download list of items'
          {...props}
        />
      ),
      custom: true,
      visible: true
    })
  }

  //  Reset the list of items after items have been archived
  const resetAfterAction = () => {
    setSelectedItems([])
    getItemsQuery({
      filters: { isArchived: false },
      sortDesc: true,
      sortField: 'createdOn'
    })
  }

  const handleArchive = () => {
    const itemsToArchive = selectedItems.length ? selectedItems : items
    setModalState({
      component: props => (
        <ConfirmationBox
          actionFn={() =>
            archiveItems(itemsToArchive, setMessage, resetAfterAction)
          }
          content={`Please note: once items are archived they are no longer editable.`}
          title='Archive list of items'
          {...props}
        />
      ),
      custom: true,
      visible: true
    })
  }

  const handleSelectItem = selectedItem => {
    const isSelected = selectedItem.itemSelected

    if (!isSelected) {
      const filteredSelectedItems = [...selectedItems, selectedItem].filter(
        item => item.id !== selectedItem.id
      )
      setSelectedItems(filteredSelectedItems)
    } else {
      setSelectedItems([...selectedItems, selectedItem])
    }
  }

  const handleEmailList = () => {
    const itemsToEmail = selectedItems.length ? selectedItems : items
    setModalState({
      component: props => (
        <ConfirmationBox
          actionFn={() => handleEmailItems(itemsToEmail, setMessage)}
          content={`${itemsToEmail.length} item${
            itemsToEmail.length > 1 ? 's' : ''
          } selected, An export of these items will be sent to your email address.`}
          title='Email list of items'
          {...props}
        />
      ),
      custom: true,
      visible: true
    })
  }

  const showEditItemModal = item => {
    setModalState({
      component: props => (
        <EditItem
          getItemsQuery={getItemsQuery}
          item={item}
          {...props} />
      ),
      visible: true
    })
  }

  const handleEditItem = () => {
    const item = selectedItems.length ? selectedItems[0] : []
    isMobile
      ? history.push({
        pathname: '/app/item/edit',
        state: { item: item }
      })
      : showEditItemModal(item)
  }

  const handleMarkAsLost = resetItems => {
    const itemsToMarkAsLost = selectedItems.length ? selectedItems : []

    if (itemsToMarkAsLost.length) {
      handleMarkItemsAsLost(itemsToMarkAsLost, setMessage, resetItems)
    }
  }

  const handleSortItems = isDescending => {
    setSortLoader(true)
    getItemsQuery({
      sortDesc: isDescending,
      sortField: 'createdOn'
    }).then(() => {
      setSortLoader(false)
    })
  }

  const handleFilterForInsured = () => {
    setFilterLoader(true)
    getItemsQuery({
      filters: {
        insured: true
      }
    }).then(() => setFilterLoader(false))
  }

  const handleFilterForLostItems = () => {
    setFilterLoader(true)
    // TODO: Implement clear filter functionality (needs a ticket)
    getItemsQuery({
      filters: {
        lostStatus: 'Misplaced'
      }
    }).then(() => setFilterLoader(false))
  }

  if (loading) {
    return (
      <div className='loader'>
        <Loader />
      </div>
    )
  }
  if (error) return ''

  const showItems = items.length > 0
  return (
    <>
      {(showItems || (filterValues && filterValues.filter)) && (
        <>
          {displaySummary && (
            <ItemsSummary
              count={itemsResponse.count}
              getItemsQuery={getItemsQuery}
              getItemsSummary={getItemsSummary} />
          )}
          {displaySummary && (
            <FormMessage
              message={formMessage.message || (error && error.toString())}
              type={error ? FormMessageTypes.error : formMessage.type}
            />
          )}
          {displaySummary && (
            <ToolBar
              clearFilter={filterValues && !!filterValues.filter}
              handlers={{
                handleArchive,
                handleDownloadPDF,
                handleEmailList,
                handleFilterForInsured,
                handleFilterForLostItems,
                handleMarkAsLost,
                handleSortItems
              }}
              isItemsSelected={!!selectedItems.length}
              resetAfterAction={resetAfterAction}
              resetItems={resetItems}
            />
          )}
          {(!sortLoader || !filterLoader) && showItems &&
          <List
            className={styles.items}
            items={items}
            renderItem={item => (
              <Item
                alternateColor={getAlternateColor()}
                handlers={{
                  handleArchive,
                  handleDownloadPDF,
                  handleEditItem,
                  handleEmailList,
                  handleMarkAsLost
                }}
                handleSelectItem={handleSelectItem}
                item={item}
                renderCheckbox={renderCheckboxes}
              />
            )}
          />
          }
          { filterValues && (
            <h5 style={{ display: !showItems && filterValues.filter && !filterLoader ? 'block' : 'none' }}>{filterValues.message}</h5>
          )}
        </>
      )}

      {(sortLoader || filterLoader) && (
        <div className='loader'>
          <Loader />
        </div>
      )}
    </>
  )
}

ItemsList.defaultProps = {
  itemsChanged: null,
  renderCheckboxes: true
}

ItemsList.propTypes = {
  count: PropTypes.number,
  displaySummary: PropTypes.bool,
  filterValues: PropTypes.object,
  getItemsQuery: PropTypes.func.isRequired,
  getItemsSummary: PropTypes.func.isRequired,
  itemsChanged: PropTypes.func,
  itemsResponse: PropTypes.object.isRequired,
  renderCheckboxes: PropTypes.bool,
  resetItems: PropTypes.func
}

export default ItemsList
