import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import { FaPen } from 'react-icons/fa'
import { composeClasses } from 'lib/utils'
import emailList from '../../assets/images/icons/emailList.svg'
import archiveItem from '../../assets/images/icons/archiveItem.svg'
import lost from '../../assets/images/icons/lost.svg'
import downloadList from '../../assets/images/icons/downloadList.svg'

import List from 'components/List/List'
import styles from './ItemActions.module.scss'

const getItemActions = (handlers, isItemsSelected) => [
  {
    action: handlers && handlers.handleEditItem,
    hide: !handlers.handleEditItem,
    icon: <FaPen fontSize='small' />,
    title: 'Edit Item'
  },
  {
    action: handlers && handlers.handleEmailList,
    icon: <img
      alt='Email List'
      src={emailList} />,
    title: 'Email List'
  },
  {
    action: handlers && handlers.handleDownloadPDF,
    icon: <img
      alt='Download List'
      src={downloadList} />,
    title: 'Download List'
  },
  {
    action: handlers && handlers.handleMarkAsLost,
    disabled: !isItemsSelected,
    icon: <img
      alt='Mark as Lost'
      src={lost} />,
    title: 'Mark as Lost'
  },
  {
    action: handlers && handlers.handleArchive,
    icon: <img
      alt='Archvie Item'
      src={archiveItem} />,
    title: 'Archive'
  }
]

const ItemActions = ({
  className,
  handlers,
  isItemsSelected,
  resetItems,
  closeActions
}) => {
  return (
    <>
      <List
        className={composeClasses(styles.actionList, className)}
        itemClassName={styles.actionItems}
        items={getItemActions(handlers, isItemsSelected)}
        renderItem={item => {
          if (!item.hide) {
            return (
              <Button
                color='info'
                disabled={item.disabled}
                onClick={e => {
                  item.action(resetItems)
                  typeof closeActions === 'function' && closeActions(e)
                }}
              >
                <span className={styles.icons}>{item.icon}</span>
                {item.title}
              </Button>
            )
          }
        }}
      />
    </>
  )
}

ItemActions.defaultProps = {
  isItemsSelected: true
}

ItemActions.propTypes = {
  className: PropTypes.string,
  closeActions: PropTypes.func,
  handlers: PropTypes.object,
  isItemsSelected: PropTypes.bool,
  resetItems: PropTypes.func
}

export default ItemActions
