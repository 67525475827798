import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { MdClear } from 'react-icons/md'
import { useMutation } from 'react-apollo'
import * as yup from 'yup'
import omit from 'lodash.omit'
import Modal from '@material-ui/core/Modal'

import history from 'lib/history'
import { FormMessageTypes, FormMessage } from 'components/Forms/Input'
import styles from './EditItem.module.scss'
import { UPDATE_ITEM, REPLACE_ITEM_FILES, REMOVE_ITEM_FILES } from 'config/graphqlDefinitions'
import ItemForm from 'components/ItemForm/ItemForm'
import Button from 'components/Button/Button'

const EditItemForm = ({ closeFn, getItemsQuery, item }) => {
  const [loading, setLoading] = useState(false)
  const [showDeleteImagePrompt, setShowDeleteImagePrompt] = useState(false)
  const [fileToDelete, setFileToDelete] = useState()

  const [updateItems, { error }] = useMutation(UPDATE_ITEM)
  const [replaceItemFiles] = useMutation(REPLACE_ITEM_FILES)
  const [removeItemFiles, { loading: removeItemFilesLoading, error: removeItemFilesError }] = useMutation(REMOVE_ITEM_FILES)

  const formHandlers = {
    /**
     * Delete a photo when it is changed
     * @param {*} file
     */
    photos (file) {
      setShowDeleteImagePrompt(true)
      setFileToDelete([{
        key: 'photos',
        url: file.name
      }])
    },

    /**
     * Delete a receipt when it is changed
     */
    receipt () {
      setShowDeleteImagePrompt(true)
      setFileToDelete([{
        key: 'receipt'
      }])
    }
  }

  const handleSubmission = async (payload, resetForm) => {
    return new Promise(async (resolve, reject) => {
      const schema = yup.object().shape({
        insurer: yup.string(),
        name: yup.string().required('Name is a required field'),
        serialNumberText: yup.string()
      })

      const errMsg = await schema.validate(payload)
        .then(() => '')
        .catch((err) => err.message)

      if (errMsg) {
        reject(new Error({
          body: {
            message: errMsg,
            type: FormMessageTypes.error
          }
        }))
      }

      // Files to be replaced
      const replaceItemVariables = []

      const payloadReceipt = payload.receipt ? payload.receipt.name : ''

      // Update the receipt if a new file was uploaded
      const updateReceipt = payloadReceipt && item.receiptUrl !== payloadReceipt
      if (updateReceipt) {
        replaceItemVariables.push({
          file: payload.receipt,
          key: 'receipt'
        })
      }

      // Find the newly uploaded files and add them to the replace list
      payload.photos
        .filter(photo => !photo.isDefaultValue)
        .forEach(photo => {
          replaceItemVariables.push({
            file: photo,
            key: 'photos'
          })
        })

      setLoading(true)

      setLoading(true)

      // Update replaced files
      const updateItemResponse = await updateItems({
        variables: {
          input: [{
            ...omit(payload, ['photos', 'receipt', 'deletedPhotos']),
            id: item.id
          }]
        }
      })
        .then(response => response)
        .catch(error => {
          reject(new Error({
            message: error.message,
            type: FormMessageTypes.error
          }))
        })

      // Replace the files
      if (replaceItemVariables.length > 0) {
        await replaceItemFiles({
          variables: {
            input: {
              files: replaceItemVariables,
              id: item.id
            }
          }
        }).catch((err) => {
          console.error('Error replacing items => ', err)
        })
      }

      const productName = updateItemResponse.data.updateItems[0].name

      setLoading(false)

      resolve({
        body: {
          message: `${productName} has been updated.`,
          subtitle: 'Go back to your dashboard to view your list.',
          type: FormMessageTypes.success
        }
      })

      // Reload the items
      if (getItemsQuery) {
        getItemsQuery()
      }

      // navigate to dashboard when on mobile
      !getItemsQuery && setTimeout(() => {
        history.push('/app/dashboard')
      }, 2000)

      closeFn && closeFn()
    })
  }

  const closeDeletePhotoModal = () => {
    setShowDeleteImagePrompt(false)
    setFileToDelete(null)

    // navigate to dashboard when on mobile
    !getItemsQuery && history.push('/app/dashboard')

    // Workaround for resetting the item form
    closeFn && closeFn()
  }

  return (
    <>
      <Modal
        aria-describedby='simple-modal-description'
        aria-labelledby='simple-modal-title'
        onClose={closeDeletePhotoModal}
        open={showDeleteImagePrompt}
      >
        <div className={styles.deletePhotoModal}>
          <div className={styles.deletePhotoModalContainer}>
            <h2 className={styles.deletePhotoModalTitle}>
              Confirm Remove
            </h2>
            <span className={styles.deletePhotoModalSubtitle}>Are you sure you want to remove this photo?</span>

            <FormMessage
              message={removeItemFilesError && 'Error removing photo'}
              type={FormMessageTypes.error} />

            <div className={styles.removeActions}>
              <Button
                className={styles.deleteButton}
                loading={removeItemFilesLoading}
                onClick={() =>
                  removeItemFiles({
                    variables: {
                      input: {
                        id: item.id,
                        keys: fileToDelete
                      }
                    }
                  }).then(() => {
                    closeDeletePhotoModal()
                    getItemsQuery && getItemsQuery()
                  })
                }
              >
                Delete
              </Button>

              <Button
                color='secondary'
                onClick={closeDeletePhotoModal}>
                Cancel
              </Button>
            </div>
          </div>

        </div>
      </Modal>

      <ItemForm
        closeFn={closeFn}
        defaultValues={item}
        error={error}
        handleFormSubmission={handleSubmission}
        handlers={formHandlers}
        loading={loading}
      />
    </>
  )
}

EditItemForm.propTypes = {
  closeFn: PropTypes.func,
  getItemsQuery: PropTypes.func,
  item: PropTypes.object.isRequired
}

const EditItem = ({ closeFn, getItemsQuery, item }) => {
  return (
    <div className={styles.addItem}>
      <header className={styles.addItemHeader}>
        Edit item
        <MdClear
          color='#acacac'
          onClick={closeFn}
          size={24}
        />
      </header>
      <div className={styles.addItemContent}>
        <EditItemForm
          closeFn={closeFn}
          getItemsQuery={getItemsQuery}
          item={item} />
      </div>
    </div>
  )
}

EditItem.propTypes = {
  closeFn: PropTypes.func,
  getItemsQuery: PropTypes.func,
  item: PropTypes.object.isRequired
}

export default EditItem
