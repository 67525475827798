import { gql } from 'apollo-boost'

export const CREATE_ACCOUNT = gql`
  mutation CreateAccount($input: CreateAccountInput) {
    createAccount(
      input: $input
    ) {
      email
      uid
      firstName
      lastName
      phoneNumber
      currency
      company {
        name
      }
      companyRole
      createdOn
      modifiedOn
    }
  }
`

export const UPDATE_ACCOUNT = gql`
  mutation UpdateAccount($input: UpdateAccountInput) {
    updateAccount(
      input: $input
    ) {
      email
      uid
      firstName
      lastName
      phoneNumber
      currency
      company {
        name
      }
      companyRole
      createdOn
      modifiedOn
    }
  }
`

export const GET_ACCOUNT = gql`
  query {
    getAccountDetails {
      uid
      email
      firstName
      lastName
      phoneNumber
      currency
      company {
        name
      }
      companyRole
      createdOn
      modifiedOn
    }
  }
`

export const INVITE_BUSINESS_USER = gql`
  mutation InviteBusinessUser($input: InviteUserInput) {
    inviteBusinessUser(
      input: $input
    ) {
      forEmail
      validUntil
    }
  }
`

export const GET_ACCOUNTS_FOR_COMPANY = gql`
  query {
    getAccountsForCompany {
      data {
        uid
        email
        firstName
        lastName
        emailVerified
        disabled
      }
      pagination {
        nextPageCursor
        currentPageCursor
      }
    }
  }
`

export const GET_INVITES_FOR_COMPANY = gql`
  query {
    getInvitesForCompany {
      data {
        accepted
        forEmail
        validUntil
      }
      pagination {
        nextPageCursor
        currentPageCursor
      }
    }
  }
`

/**
 * Add a new item
 */
export const ADD_ITEM = gql`
  mutation (
    $name: String!
    $insuredValue: Float
    $receipt: Upload
    $photos: [Upload!]
    # $serialNumberPhoto: Upload
    $serialNumberText: String
    $lostStatus: LostStatus
    $purchasedAt: Date
    $insurer: String # ID of the insurer
    $assignee: String
  ) {
    addItem(input: {
      name: $name,
      insuredValue: $insuredValue,
      receipt: $receipt,
      photos: $photos,
      serialNumberText: $serialNumberText,
      lostStatus: $lostStatus,
      purchasedAt: $purchasedAt,
      insurer: $insurer,
      assignee: $assignee
    }){
      # id
      name
      insuredValue
      receiptUrl
      assignee
      photoUrls
      serialNumberPhotoUrl
      serialNumberText
      isArchived
      lostStatus
      lostAt
      purchasedAt
      createdOn
      modifiedOn
      # insurer{
      #   id
      #   name
      #   email
      #   phoneNumber
      #   createdOn
      #   modifiedOn
      # }
    }
  }
`

export const GET_ITEMS = gql`
  query GetItems($pageSize: Int, $startCursor: String, $sortField: ItemSortField, $sortDesc: Boolean, $filters: ItemFilter){
    getItems(pageSize: $pageSize, startCursor: $startCursor, sortField: $sortField, sortDesc: $sortDesc, filters: $filters ){
      data{
        id
        name
        insuredValue
        receiptUrl
        assignee
        photoUrls
        serialNumberPhotoUrl
        serialNumberText
        isArchived
        lostStatus
        lostAt
        purchasedAt
        createdOn
        modifiedOn
        insurer {
          id
          name
        }
      }
      count
      pagination{
        nextPageCursor
        currentPageCursor
      }
    }
  }
`

/**
 * Update item
 */
export const UPDATE_ITEM = gql`
  mutation UpdateItem(
    $input: [UpdateItemInput]
  ) {
    updateItems(input: $input){
      id
      name
      insuredValue
      receiptUrl
      assignee
      photoUrls
      serialNumberPhotoUrl
      serialNumberText
      isArchived
      lostStatus
      lostAt
      purchasedAt
      createdOn
      modifiedOn
      insurer{
        id
        name
        email
        phoneNumber
        createdOn
        modifiedOn
      }
    }
  }
`

export const GET_INSURERS = gql`
  query{
    getInsurers{
      data{
        id
        name
        email
        phoneNumber
        createdOn
        modifiedOn
      }
      pagination{
        nextPageCursor
        currentPageCursor
      }
    }
  }
`

export const GENERATE_ITEMS_PDF = gql`
  query GenerateItemsPdfLink($selectedItems: [SelectedItemInput]){
    generateItemsPdfLink(selectedItems: $selectedItems)
  }
`

export const EMAIL_ITEMS = gql`
  query EmailItems($selectedItems: [SelectedItemInput]){
    emailItems(selectedItems: $selectedItems)
  }
`

/**
 * Replace uploaded files for an item
 */
export const REPLACE_ITEM_FILES = gql`
  mutation ReplaceItemFile ($input: ReplaceItemFileInput!){
    replaceItemFile(input: $input){
      id
      receiptUrl
      photoUrls
    }
  }
`

/**
 * Remove specified item files for an item
 */
export const REMOVE_ITEM_FILES = gql`
  mutation RemoveItemFile ($input: RemoveItemFileInput!){
    removeItemFile(input: $input){
      receiptUrl
      photoUrls
    }
  }
`
