import React from 'react'
import PropTypes from 'prop-types'

export const FormatCurrency = ({ value, ...props }) => {
  return (
    <div {...props}>
      {new Intl.NumberFormat('en-ZA', {
        currency: 'ZAR',
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
        style: 'currency'
      }).format(value)}
    </div>
  )
}

FormatCurrency.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string.isRequired
}

export const FormatDate = ({ date, ...props }) => {
  const value = new Date(date)
  return (
    <div {...props}>
      {new Intl.DateTimeFormat('en-ZA', {
        day: '2-digit',
        month: 'short'
      }).format(value)}
    </div>
  )
}

FormatDate.propTypes = {
  date: PropTypes.string.isRequired
}
