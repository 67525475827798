import React from 'react'
import { Link } from 'react-router-dom'
import Button from 'components/Button/Button'
import styles from './Signup.module.scss'
import authStyles from '../auth.module.scss'
import { AuthCTA } from '../auth.shared'

const Signup = () => {
  return (
    <>
      <div className={authStyles.loginHeading}>
        <span>Sign up</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <p className={styles.signup}>
            Welcome to SureSure!
            <br />
            Create an account to get started.
          </p>
        </main>
        <hr className={authStyles.line} />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/signup/business'>
              <Button>SureSure for Business</Button>
            </Link>
          )}
          text='If you are a business and want to add your business assets and items, choose SureSure for  Business'
        />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/signup/personal'>
              <Button>SureSure Personal</Button>
            </Link>
          )}
          text='If you are a person and want to add your personal items, choose SureSure Personal'
        />
      </div>
    </>
  )
}

export default Signup
