import firebase from 'services/firebase'
import history from 'lib/history'
import { FormMessageTypes } from 'components/Forms/Input'
import reqClient from 'lib/reqClient'
import { INVITE_BUSINESS_USER, UPDATE_ACCOUNT } from 'config/graphqlDefinitions'

/**
 * Invite an Employee
 * @param {*} event
 * @param {*} setDispatch
 */
export const handleSendInvite = (event, setDispatch) => {
  event.preventDefault()
  const resendInviteEmail = event.target.dataset && event.target.dataset.email

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  const email = document.getElementById('email') ? document.getElementById('email').value : resendInviteEmail
  const inviteUser = {
    email
  }

  firebase
    .auth()
    .fetchSignInMethodsForEmail(email)
    .then(response => {
      // Check if email exists if firebase
      if (response.length) {
        return setDispatch({
          loading: false,
          message: 'A user with that email already exists',
          type: FormMessageTypes.error
        })
      }

      reqClient.mutate({
        mutation: INVITE_BUSINESS_USER,
        variables: {
          input: inviteUser
        }
      })
        .then(response => {
          setDispatch({
            loading: false,
            message: 'Your invitation have been sent',
            type: FormMessageTypes.success
          })

          setTimeout(() => {
            history.push('/app/account/team')
          }, 2000)
        })
        .catch(err => {
          setDispatch({
            loading: false,
            message: err.message || 'Unknown error while creating account',
            type: FormMessageTypes.error
          })
        })
    })
    .catch(err => {
      setDispatch({
        message: err.message || 'Unknown error while signing in',
        type: FormMessageTypes.error
      })
    })
}

export const handleAccountUpdate = (event, { isDisabled, uid, ...values }, setDispatch, setIsDisabled) => {
  event.preventDefault()
  const payload = setIsDisabled ? { disabled: !isDisabled } : values
  payload.uid = uid

  // Reset initial state
  setDispatch({
    loading: true,
    message: '',
    type: ''
  })

  reqClient
    .mutate({
      mutation: UPDATE_ACCOUNT,
      variables: {
        input: payload
      }
    })
    .then(() => {
      setIsDisabled && setIsDisabled(!isDisabled)
      const message = setIsDisabled && isDisabled ? 'Account has been activated' : 'Account has been deactivated'

      setDispatch({
        loading: false,
        message: setIsDisabled ? message : 'Account has been updated',
        type: FormMessageTypes.success
      })
    })
    .catch(err => {
      setDispatch({
        loading: false,
        message: err.message || 'Unknown error while updating account',
        type: FormMessageTypes.error
      })
    })
}
