import React from 'react'
import { ExpandLess, ExpandMore } from '@material-ui/icons'
// import { FaFilter, FaSortAmountDown, FaSortAmountUp } from 'react-icons/fa'

import Button from 'components/Button/Button'
import ItemActions from 'components/ItemActions/ItemActions'
import ItemFilters from 'components/ItemFilters/ItemFilters'
import styles from './Toolbar.module.scss'
import PropTypes from 'prop-types'

class Toolbar extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      filterItems: false,
      showActions: false,
      showFilters: false,
      sortItems: true
    }
    this.props = props
    this.showDropDownOptions = this.showDropDownOptions.bind(this)
    this.handleSortItems = this.handleSortItems.bind(this)
  }

  showDropDownOptions (e, type) {
    if (e) {
      e.preventDefault()
      e.stopPropagation()
    }
    this.setState({
      [type]: !this.state[type]
    })
  }

  handleSortItems (e) {
    e.preventDefault()
    e.stopPropagation()
    const isDescending = !this.state.sortItems
    this.props.handlers.handleSortItems(isDescending)
    this.setState({
      sortItems: !this.state.sortItems
    })
  }

  handleFilterItems (e) {
    e.preventDefault()
    e.stopPropagation()
    this.setState({
      filterItems: !this.state.filterItems
    })
  }

  componentWillMount () {
    document.addEventListener('click', this.handleClick, false)
  }

  componentWillUnmount () {
    document.removeEventListener('click', this.handleClick, false)
  }

  handleClick = event => {
    if (!this.node.contains(event.target)) {
      this.handleClickOutside(event)
    }
  }

  handleClickOutside = () => {
    const { showActions, showFilters } = this.state
    if (showActions) {
      this.showDropDownOptions(null, 'showActions')
    }
    if (showFilters) {
      this.showDropDownOptions(null, 'showFilters')
    }
  }

  render () {
    return (
      <div
        className={styles.container}
        ref={node => {
          this.node = node
        }}
      >
        <div className={styles.buttonGroup}>
          <div className={styles.actions}>
            <Button
              className={styles[this.state.showActions ? 'active' : '']}
              color='secondary'
              onClick={e => this.showDropDownOptions(e, 'showActions')}
              type='button'
            >
              <span>Actions</span>
              {this.state.showActions ? <ExpandLess /> : <ExpandMore />}
            </Button>
            {this.state.showActions && (
              <ItemActions
                closeActions={e => this.showDropDownOptions(e, 'showActions')}
                handlers={this.props.handlers}
                isItemsSelected={this.props.isItemsSelected}
                resetItems={this.props.resetItems}
              />
            )}
          </div>
          <Button
            color='secondary'
            onClick={e => this.props.resetAfterAction()}
            style={{ display: this.props.clearFilter ? 'block' : 'none' }}>
            <span>Clear Filter</span>
          </Button>
          <Button
            color='secondary'
            onClick={e => this.handleSortItems(e)}>
            <span>Sort</span>
            {this.state.sortItems ? <ExpandLess /> : <ExpandMore />}
          </Button>
          <div className={styles.filters}>
            <Button
              className={styles[this.state.showFilters ? 'active' : '']}
              color='secondary'
              onClick={e => this.showDropDownOptions(e, 'showFilters')}
            >
              <span>Filter</span>
              <ExpandMore />
            </Button>
            {this.state.showFilters && (
              <ItemFilters handlers={this.props.handlers} />
            )}
          </div>
        </div>
      </div>
    )
  }
}

Toolbar.propTypes = {
  clearFilter: PropTypes.bool,
  handlers: PropTypes.object,
  isItemsSelected: PropTypes.bool,
  resetAfterAction: PropTypes.func,
  resetItems: PropTypes.func
}

export default Toolbar
