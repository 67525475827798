import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import history from 'lib/history'
import { FormGroup, Label, Input, FormMessage } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import authStyles from '../auth.module.scss'
import { handleForgotPassword } from '../auth.service'
import { AuthCTA } from '../auth.shared'
import { MdArrowBack } from 'react-icons/md'

const ForgotPassword = () => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Forgot Password</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <form onSubmit={e => handleForgotPassword(e, setDispatch)}>
            <FormGroup>
              <Label htmlFor='email'>Email Address</Label>
              <Input
                id='email'
                name='email'
                required
                type='email' />
            </FormGroup>
            <FormMessage
              message={dispatch.message}
              type={dispatch.type} />
            <Button
              loading={dispatch.loading}
              type='submit'>
              Reset Password
            </Button>
          </form>
        </main>
        <hr className={authStyles.line} />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/login'>
              <Button className={authStyles.grayBtn}>Login</Button>
            </Link>
          )}
          title='Return to login'
        />
      </div>
    </>
  )
}

export default ForgotPassword
