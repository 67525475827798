import React from 'react'
import PropTypes from 'prop-types'
import { CSSTransition } from 'react-transition-group'

const GenericTransition = ({ children, show }) => {
  return (
    <CSSTransition
      classNames='generic-transition'
      in={show}
      timeout={200}
      unmountOnExit
    >
      {children}
    </CSSTransition>
  )
}

GenericTransition.propTypes = {
  children: PropTypes.element.isRequired,
  show: PropTypes.bool
}

export default GenericTransition
