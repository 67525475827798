import React from 'react'
import PropTypes from 'prop-types'

import { composeClasses } from 'lib/utils'
import { FaTimes, FaCheck, FaQuestion } from 'react-icons/fa'

import styles from './Badge.module.scss'

const Badge = ({ title, type }) => {
  return (
    <div className={composeClasses(styles.container, styles[type])}>
      {type === 'success' && (
        <FaCheck fontSize='inherit' />
      )}
      {type === 'error' && (
        <FaTimes fontSize='inherit' />
      )}
      {type === 'lost' && (
        <FaQuestion fontSize='inherit' />
      )}
      <span>{title}</span>
    </div>
  )
}

Badge.propTypes = {
  title: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired
}

export default Badge
