import React, { useContext } from 'react'
import { isMobile } from 'react-device-detect'
import { ModalContext } from 'context'
import PropTypes from 'prop-types'
import styles from './modal.module.scss'
import { composeClasses } from 'lib/utils'

const Modal = ({
  component: Component,
  title,
  fullModal,
  modalContentStyle,
  headerStyle,
  position,
  custom
}) => {
  const { setModalState } = useContext(ModalContext)

  const closeModalHandler = () => {
    setModalState({
      visible: false
    })
  }

  return (
    <div className={styles.modal}>
      <div
        className={styles.modalBackDrop}
        onClick={() => closeModalHandler()}
      />
      {
        custom ? (
          Component && <Component closeFn={closeModalHandler} />
        ) : isMobile && !fullModal ? (
          <div className={styles.mobileModal}>{Component && <Component />}</div>
        ) : (
          <div
            className={composeClasses(
              styles.desktopModal,
              position === 'center' && styles.center
            )}
          >
            {title && (
              <header
                className={composeClasses(
                  styles.desktopModalHeader,
                  headerStyle || ''
                )}
              >
                {title}
              </header>
            )}
            <div
              className={
                (!fullModal && modalContentStyle) || styles.desktopModalContent
              }
            >
              {Component && <Component closeFn={() => closeModalHandler()} />}
            </div>
          </div>
        )}
    </div>
  )
}

Modal.defaultProps = {
  custom: false
}

Modal.propTypes = {
  component: PropTypes.node,
  custom: PropTypes.bool,
  fullModal: PropTypes.bool,
  headerStyle: PropTypes.string,
  modalContentStyle: PropTypes.bool,
  position: PropTypes.string,
  title: PropTypes.string
}

export default Modal
