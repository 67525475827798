import React, { useState } from 'react'
import history from 'lib/history'
import { FormGroup, Label, FormMessage } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import PasswordField from 'components/Forms/PasswordField'
import authStyles from '../auth.module.scss'
import { handleConfirmPasswordReset } from '../auth.service'
import { MdArrowBack } from 'react-icons/md'

const ResetPassword = () => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Reset Password</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <form onSubmit={e => handleConfirmPasswordReset(e, setDispatch)}>
            <FormGroup>
              <Label htmlFor='password'>Password</Label>
              <PasswordField
                id='password'
                name='password'
                required />
            </FormGroup>

            <FormGroup>
              <Label htmlFor='confirmPassword'>Confirm Password</Label>
              <PasswordField
                id='confirmPassword'
                name='confirmPassword'
                required
              />
            </FormGroup>
            <FormMessage
              message={dispatch.message}
              type={dispatch.type} />
            <Button
              loading={dispatch.loading}
              type='submit'>
              Change Password
            </Button>
          </form>
        </main>
        <hr className={authStyles.line} />
      </div>
    </>
  )
}

export default ResetPassword
