import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { FormGroup, Label, Input, FormMessage } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import PasswordField from 'components/Forms/PasswordField'
import authStyles from '../auth.module.scss'
import { handleLogin } from '../auth.service'
import { AuthCTA } from '../auth.shared'
import { getParameterByName } from 'lib/utils'

const Login = () => {
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  useEffect(() => {
    const isExpired = getParameterByName('expired')

    if (isExpired) {
      setDispatch({
        message: 'Your session has expired. Please login to continue.',
        type: 'error'
      })
    }
  }, [])

  return (
    <>
      <div className={authStyles.loginHeading}>
        <span className={authStyles.green}>Login</span>
      </div>
      <div className={authStyles.container}>
        <main>
          <form onSubmit={e => handleLogin(e, setDispatch)}>
            <FormGroup>
              <Label htmlFor='email'>Email Address</Label>
              <Input
                id='email'
                name='email'
                required
                type='email' />
            </FormGroup>

            <FormGroup>
              <div className={authStyles.passwordField}>
                <Label htmlFor='password'>Password</Label>
                <Link
                  className={authStyles.forgotPassword}
                  tabIndex={-1}
                  to='/auth/forgot-password'
                >
                  Forgot password?
                </Link>
              </div>
              <PasswordField
                id='password'
                name='password'
                required />
            </FormGroup>
            <FormMessage
              message={dispatch.message}
              type={dispatch.type} />

            <Button
              loading={dispatch.loading}
              type='submit'>
              Login
            </Button>
          </form>
        </main>
        <hr className={authStyles.line} />
        <AuthCTA
          ctaButton={() => (
            <Link to='/auth/signup'>
              <Button className={authStyles.grayBtn}>Create an Account</Button>
            </Link>
          )}
          title="Don't have an account yet?"
        />
      </div>
    </>
  )
}

export default Login
