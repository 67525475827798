import React from 'react'
import PropTypes from 'prop-types'

import styles from './List.module.scss'
import { composeClasses } from 'lib/utils'

/**
 * Renders a list of items
 *  @param {*} props {
 *  @param {String} className
 *  @param {Array} items List items
 *  @param {String} itemClassName
 * }
 * @returns {React.Component} list
 */

const isNotEmptyArray = arr => Array.isArray(arr) && arr.length > 0

const List = ({ className, itemClassName, items, renderItem }) => {
  // Prevent list from rendering empty
  // if (!Array.isArray(items) || items.length < 1) return null;

  return (
    <div className={composeClasses(className, styles.listContainer)}>
      <ul className={composeClasses(styles.list)}>
        {isNotEmptyArray(items) &&
          items.map((item) => (
            <li
              className={composeClasses(styles.listItem, itemClassName)}
              key={item.modifiedOn || item.id}
            >
              {renderItem(item)}
            </li>
          ))}
      </ul>
    </div>
  )
}

List.defaultProps = {
  className: '',
  itemClassName: ''
}

List.propTypes = {
  className: PropTypes.string,
  itemClassName: PropTypes.string,
  items: PropTypes.array.isRequired,
  renderItem: PropTypes.func.isRequired
}

export default List
