import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import styles from './ConfirmationBox.module.scss'

const ConfirmationBox = ({ actionFn, closeFn, content, title }) => {
  return (
    <div className={styles.confirmationBox}>
      {title && <h3 className={styles.confirmationBoxTitle}>{title}</h3>}
      {content && <p className={styles.confirmationBoxContent}>{content}</p>}
      <div className={styles.confirmationBoxActions}>
        <Button
          className={styles.submitButton}
          onClick={() => {
            actionFn()
            closeFn()
          }}
          type='button'
        >
          Send
        </Button>
        <Button
          className={styles.cancelButton}
          onClick={closeFn}
          type='button'>
          Cancel
        </Button>
      </div>
    </div>
  )
}

ConfirmationBox.propTypes = {
  actionFn: PropTypes.func,
  closeFn: PropTypes.func,
  content: PropTypes.string,
  title: PropTypes.string
}

export default ConfirmationBox
