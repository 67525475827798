import React from 'react'
import PropTypes from 'prop-types'
import Button from 'components/Button/Button'
import { composeClasses } from 'lib/utils'
import lost from '../../assets/images/icons/lost.svg'
import insuredFilter from '../../assets/images/icons/insuredFilter.svg'

import List from 'components/List/List'
import styles from './ItemFilters.module.scss'

const getItemFilters = handlers => [
  {
    action: handlers && handlers.handleFilterForLostItems,
    icon: <img
      alt='Lost Items'
      src={lost} />,
    title: 'Lost Items'
  },
  {
    action: handlers && handlers.handleFilterForInsured,
    icon: <img
      alt='Lost Items'
      src={insuredFilter} />,
    title: 'Insured Items'
  }
]

const ItemFilters = ({ className, handlers }) => {
  return (
    <>
      <List
        className={composeClasses(styles.filterList, className)}
        itemClassName={styles.filterItems}
        items={getItemFilters(handlers)}
        renderItem={item => {
          return (
            <Button
              color='info'
              disabled={item.disabled}
              onClick={() => item.action()}
            >
              <span className={styles.icons}>{item.icon}</span>
              {item.title}
            </Button>
          )
        }}
      />
    </>
  )
}

ItemFilters.propTypes = {
  className: PropTypes.string,
  handlers: PropTypes.object
}

export default ItemFilters
