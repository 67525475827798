import React, { useState } from 'react'

import { FormGroup, Label, Input, FormMessage } from 'components/Forms/Input'
import Button from 'components/Button/Button'
import { sendMessage } from 'services/contact'
import { serializeForm } from 'lib/forms'

import './Contact.style.css'
import Footer from 'components/Footer/Footer'

const Contact = () => {
  const [key, setKey] = useState(0)
  const [dispatch, setDispatch] = useState({
    loading: false,
    message: '',
    type: ''
  })

  const handleContactSubmit = e => {
    e.preventDefault()
    e.persist()
    setDispatch({ loading: true })

    const data = serializeForm(e)

    sendMessage(data)
      .then(res => {
        setDispatch({ loading: false, message: res, type: 'success' })
        setKey(key + 1) // this will help to reset the form element
      })
      .catch(err => {
        setDispatch({ loading: false, message: err.message, type: 'error' })
      })
  }

  return (
    <>
      <div className='wrap-content-container'>
        <div className='wrap-content'>
          <div className='contact-container'>
            <div className='contact-content'>
              <div className='contactInfo'>
                <h2 className='heading'>Get in touch</h2>
              </div>
              <div className='form-content'>
                <form
                  key={key}
                  onSubmit={handleContactSubmit}>
                  <FormGroup>
                    <Label htmlFor='name'>Name</Label>
                    <Input
                      id='name'
                      name='name'
                      required
                      type='text' />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='email'>Email Address</Label>
                    <Input
                      id='email'
                      name='email'
                      required
                      type='email' />
                  </FormGroup>
                  <FormGroup>
                    <Label htmlFor='message'>Message</Label>
                    <textarea
                      id='message'
                      name='message'
                      required />
                  </FormGroup>

                  <FormMessage
                    message={dispatch.message}
                    type={dispatch.type}
                  />

                  <Button
                    loading={dispatch.loading}
                    type='submit'>
                    Send Message
                  </Button>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  )
}

export default Contact
