import React, { useEffect, useState } from 'react'
import { MdArrowBack } from 'react-icons/md'
import Button from 'components/Button/Button'
import authStyles from '../auth.module.scss'
import { AuthCTA } from '../../../containers/Auth/auth.shared'
import { logoutHandler, handleVerifyEmail, handleResetPassword } from 'containers/Auth/auth.service'
import history from 'lib/history'
import Loader from 'components/Loader/Loader'
import { getParameterByName } from 'lib/utils'

const EmailActions = () => {
  const [dispatch, setDispatch] = useState({
    loading: true,
    message: '',
    type: ''
  })

  useEffect(() => {
    const mode = getParameterByName('mode')

    switch (mode) {
      case 'resetPassword':
        handleResetPassword(setDispatch)
        break
      case 'verifyEmail':
        handleVerifyEmail()
          .then(res => {
            setDispatch({
              loading: false,
              message: 'Your email address has been verified successfully. Login to get started!',
              type: 'success'
            })
          })
          .catch(error => {
            setDispatch({
              loading: false,
              message: `Email verification failed: ${error.message}`,
              type: 'error'
            })
          })
        break
      default:
        break
    }
  }, [])

  if (dispatch.loading) return <Loader />

  return (
    <>
      <div className={authStyles.loginHeading}>
        <MdArrowBack
          className={authStyles.backBtn}
          onClick={event => history.goBack()}
        />
        <span>Verified</span>
      </div>
      <div className={authStyles.container}>
        {dispatch.type === 'error' && (
          <AuthCTA
            ctaButton={() => (
              <Button
                loading={dispatch.loading}
                onClick={event => history.push('/auth/login')}>
                Login
              </Button>
            )}
            title={dispatch.message}
          />
        )}

        {dispatch.type === 'success' && (
          <>
            <p className={authStyles.dispatchMessage}>{dispatch.message}</p>
            <div className={authStyles.btnWrapper}>
              <Button
                className={authStyles.loginBtn}
                onClick={event => logoutHandler()}
              >
                Log In
              </Button>
            </div>
          </>
        )}
      </div>
    </>
  )
}

export default EmailActions
