import React from 'react'
import styles from './Home.module.scss'
import Button from 'components/Button/Button'
import history from 'lib/history'

import heroImage from '../../../assets/images/hero-image.png'

import Footer from 'components/Footer/Footer'

const Home = () => (
  <>
    <div className={styles.container}>
      <div className={styles.content}>
        <h2 className={`${styles.green} ${styles.firstTitle}`}>Gotta be</h2>
        <h2 className={`${styles.green} ${styles.title}`}>SURE SURE</h2>
        <h4 className={`${styles.green} ${styles.reducedTitle} ${styles.body} ${styles.margin}`}>Web application available for desktop and mobile</h4>

        <div className={styles.buttonContainer}>
          <div className={`${styles.content} ${styles.buttonColumn}`}>
            <Button
              onClick={event => history.push('/auth/login')}
            >
              Login
            </Button>
          </div>
          <div className={`${styles.content} ${styles.buttonColumn}`}>
            <Button
              color='secondary'
              onClick={event => history.push('/auth/signup/personal')}
            >
              Sign Up Personally
            </Button>
            <Button
              color='secondary'
              onClick={event => history.push('/auth/signup/business')}
            >
              Sign Up Your Business
            </Button>
          </div>
        </div>
      </div>
      <div className={styles.content}>
        <h3 className={`${styles.green} ${styles.reducedTitle}`}>You don’t know what</h3>
        <h3 className={`${styles.green} ${styles.reducedTitle} ${styles.titleMargin}`}>you got till it’s gone</h3>
        <p className={`${styles.green} ${styles.body}`}>
          <p className={styles.paragraph}>We all know the feeling.</p>
          That nauseating shudder that ripples through your body as you realise
          that what was once there, is no longer. We also know that you don’t need the mountain of admin
          that comes with trying to claim back on your precious belongings. This is why we’ve developed
          SURESURE, the simplest way to keep track of your sh^t. All you need to do is signup, add your
          items, and we’ll make sure-sure that in the unfortunate event of loss or damage, getting back
          what’s yours will be smooth sailing.
        </p>
        <img
          alt='suresure hero'
          className={styles.heroImage}
          src={heroImage} />
      </div>
    </div>
    <Footer />
  </>
)

export default Home
