import React from 'react'
import EditItemComponent from 'components/EditItem'
import PropTypes from 'prop-types'

const EditItem = ({ ...props }) => {
  return <EditItemComponent item={props.location.state.item} />
}

EditItem.propTypes = {
  location: PropTypes.object
}

export default EditItem
